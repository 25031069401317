textarea.svelte-po3n73 {
    display: block;
    resize: vertical;
    width: var(--wx-input-width);
    max-width: 100%;
    padding: var(--wx-input-padding);
    outline: none;
    min-height: 100px;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background)
}

textarea.svelte-po3n73:focus {
    border: var(--wx-input-border-focus)
}

textarea.svelte-po3n73::-moz-placeholder {
    color: var(--wx-input-placeholder-color)
}

textarea.svelte-po3n73::placeholder {
    color: var(--wx-input-placeholder-color)
}

textarea[disabled].svelte-po3n73 {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled);
    resize: none
}

textarea[disabled].svelte-po3n73::-moz-placeholder {
    color: var(--wx-color-font-disabled)
}

textarea[disabled].svelte-po3n73::placeholder {
    color: var(--wx-color-font-disabled)
}

textarea.error.svelte-po3n73 {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

button.svelte-tksh4t.svelte-tksh4t {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    text-align: center;
    letter-spacing: normal;
    text-transform: var(--wx-button-text-transform);
    font-family: var(--wx-button-font-family);
    font-size: var(--wx-button-font-size);
    line-height: var(--wx-button-line-height);
    font-weight: var(--wx-button-font-weigth);
    padding: var(--wx-button-padding);
    border: var(--wx-button-border);
    border-radius: var(--wx-button-border-radius);
    background-color: var(--wx-button-background);
    color: var(--wx-button-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    min-width: var(--wx-button-width)
}

button.svelte-tksh4t.svelte-tksh4t:hover {
    background-image: linear-gradient(
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.1) 100%
    )
}

button.svelte-tksh4t.svelte-tksh4t, button.svelte-tksh4t.svelte-tksh4t:focus, button.svelte-tksh4t.svelte-tksh4t:active {
    outline: none
}

button.svelte-tksh4t.svelte-tksh4t:active:not([disabled]) {
    opacity: 0.8
}

button[disabled].svelte-tksh4t.svelte-tksh4t {
    cursor: not-allowed;
    background: var(--wx-color-disabled);
    border-color: transparent;
    color: var(--wx-color-font-disabled)
}

.block.svelte-tksh4t.svelte-tksh4t {
    display: block;
    width: 100%
}

.square.svelte-tksh4t.svelte-tksh4t {
    border-radius: 0
}

i.svelte-tksh4t.svelte-tksh4t {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: var(--wx-button-icon-size);
    line-height: 1;
    height: var(--wx-button-line-height);
    margin-right: 2px;
    opacity: 0.7
}

i.svelte-tksh4t.svelte-tksh4t:before {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}

.icon.svelte-tksh4t.svelte-tksh4t {
    padding-left: var(--wx-button-icon-indent);
    padding-right: var(--wx-button-icon-indent);
    min-width: auto
}

.icon.svelte-tksh4t i.svelte-tksh4t {
    margin: 0;
    opacity: 1
}

.primary.svelte-tksh4t.svelte-tksh4t {
    background-color: var(--wx-color-primary);
    color: var(--wx-color-primary-font)
}

.secondary.svelte-tksh4t.svelte-tksh4t {
    background: var(--wx-color-secondary);
    color: var(--wx-color-secondary-font);
    border-color: var(--wx-color-secondary-border)
}

.secondary.svelte-tksh4t.svelte-tksh4t:hover:not([disabled]), .secondary.pressed.svelte-tksh4t.svelte-tksh4t:not([disabled]), .secondary.pressed.svelte-tksh4t.svelte-tksh4t:hover:not([disabled]), .secondary.pressed.svelte-tksh4t.svelte-tksh4t:active:not([disabled]) {
    background: var(--wx-color-secondary-hover);
    color: var(--wx-color-secondary-font-hover)
}

.secondary[disabled].svelte-tksh4t.svelte-tksh4t {
    border-color: var(--wx-color-secondary-border-disabled)
}

.danger.svelte-tksh4t.svelte-tksh4t {
    background-color: var(--wx-color-danger);
    color: var(--wx-button-danger-font-color)
}

.link.svelte-tksh4t.svelte-tksh4t {
    color: var(--wx-color-link);
    padding: 0;
    border: none;
    vertical-align: baseline
}

.link.svelte-tksh4t.svelte-tksh4t, .link.svelte-tksh4t.svelte-tksh4t:hover, .link[disabled].svelte-tksh4t.svelte-tksh4t {
    background: transparent
}

.link[disabled].svelte-tksh4t.svelte-tksh4t {
    color: var(--wx-color-font-disabled)
}

.pressed.svelte-tksh4t.svelte-tksh4t, .pressed.svelte-tksh4t.svelte-tksh4t:hover, .pressed.svelte-tksh4t.svelte-tksh4t:active, .pressed[disabled].svelte-tksh4t.svelte-tksh4t {
    opacity: 0.8;
    background-image: linear-gradient(
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.1) 100%
    );
    box-shadow: inset 0 2px 2px 1px rgba(0, 0, 0, 0.15)
}

div.svelte-1v5nu6d.svelte-1v5nu6d {
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: var(--wx-input-width)
}

input.svelte-1v5nu6d.svelte-1v5nu6d {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0
}

label.svelte-1v5nu6d.svelte-1v5nu6d {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    font-family: var(--wx-checkbox-font-family);
    font-size: var(--wx-checkbox-font-size);
    line-height: var(--wx-checkbox-line-height);
    font-weight: var(--wx-checkbox-font-weight);
    color: var(--wx-checkbox-font-color);
    cursor: pointer
}

span.svelte-1v5nu6d.svelte-1v5nu6d {
    display: block
}

span.svelte-1v5nu6d + span.svelte-1v5nu6d {
    margin-left: 8px;
    padding-top: calc(
            (var(--wx-checkbox-height) - var(--wx-checkbox-line-height)) / 2
    );
    padding-bottom: calc(
            (var(--wx-checkbox-height) - var(--wx-checkbox-line-height)) / 2
    )
}

span.svelte-1v5nu6d.svelte-1v5nu6d:first-child {
    position: relative;
    flex-shrink: 0;
    padding-top: calc(
            (var(--wx-checkbox-height) - var(--wx-checkbox-size)) / 2
    );
    padding-bottom: calc(
            (var(--wx-checkbox-height) - var(--wx-checkbox-size)) / 2
    )
}

span.svelte-1v5nu6d.svelte-1v5nu6d:first-child:before {
    content: "";
    display: block;
    width: var(--wx-checkbox-size);
    height: var(--wx-checkbox-size);
    border: var(--wx-checkbox-border-width) solid var(--wx-checkbox-border-color);
    border-radius: var(--wx-checkbox-border-radius);
    background: var(--wx-input-background)
}

span.svelte-1v5nu6d.svelte-1v5nu6d:first-child:after {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: calc(var(--wx-checkbox-size) * 0.56);
    height: calc(var(--wx-checkbox-size) * 0.32);
    border-style: solid;
    border-color: var(--wx-color-primary-font);
    border-width: 0 0 calc(var(--wx-checkbox-size) * 0.12) calc(var(--wx-checkbox-size) * 0.12);
    transform: rotate(-45deg);
    margin-left: calc(var(--wx-checkbox-size) * -0.26);
    margin-top: calc(var(--wx-checkbox-size) * -0.24)
}

input:checked ~ label.svelte-1v5nu6d span.svelte-1v5nu6d:first-child:before {
    background: var(--wx-color-primary);
    border-color: transparent
}

input:checked ~ label.svelte-1v5nu6d span.svelte-1v5nu6d:first-child:after {
    display: block
}

input[disabled].svelte-1v5nu6d ~ label.svelte-1v5nu6d {
    color: var(--wx-checkbox-border-color-disabled);
    cursor: not-allowed
}

input[disabled]:not(:checked) ~ label.svelte-1v5nu6d span.svelte-1v5nu6d:first-child:before {
    border-color: var(--wx-checkbox-border-color-disabled)
}

input[disabled]:checked ~ label.svelte-1v5nu6d span.svelte-1v5nu6d:first-child:before {
    background: var(--wx-checkbox-border-color-disabled)
}

input[disabled] ~ label.svelte-1v5nu6d span.svelte-1v5nu6d:first-child:after {
    border-color: var(--wx-input-background)
}

.group.svelte-txfdxf.svelte-txfdxf {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: calc(var(--wx-field-gutter) * -1)
}

.group-item.svelte-txfdxf.svelte-txfdxf {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: var(--wx-field-gutter)
}

.group.inline.svelte-txfdxf .group-item.svelte-txfdxf {
    flex: none;
    padding-right: var(--wx-field-gutter)
}

.group.grid.svelte-txfdxf .group-item.svelte-txfdxf {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: var(--wx-field-gutter)
}

.color-select.svelte-1dmje9x {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px;
    width: 100%
}

.color-block.svelte-1dmje9x {
    height: 140px;
    width: 100%;
    position: relative;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2)
}

.color-block.svelte-1dmje9x::before, .color-block.svelte-1dmje9x::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute
}

.color-block.svelte-1dmje9x:before {
    background-image: linear-gradient(0deg, #000, hsla(0, 0%, 100%, 0));
    z-index: 2
}

.color-block.svelte-1dmje9x::after {
    background-image: linear-gradient(90deg, #fff, hsla(20, 42%, 65%, 0));
    z-index: 1
}

.color-block__slider.svelte-1dmje9x {
    height: 16px;
    width: 16px;
    margin: -8px 0 0 -8px
}

.slider.svelte-1dmje9x {
    border: 2px solid white;
    border-radius: 50%;
    position: absolute;
    z-index: 3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2)
}

.slider.svelte-1dmje9x:focus, .slider.svelte-1dmje9x:hover {
    outline: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), inset 0 0 4px #ffffff
}

.color-line.svelte-1dmje9x {
    width: 100%;
    height: 8px;
    background-image: linear-gradient(
            90deg,
            red,
            #ff0 17%,
            #0f0 33%,
            #0ff 50%,
            #00f 67%,
            #f0f 83%,
            red
    );
    position: relative;
    border-radius: 6px
}

.color-line__slider.svelte-1dmje9x {
    height: 14px;
    width: 14px;
    margin: 0 0 0 -7px;
    top: -4px
}

.color-controls.svelte-1dmje9x {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.color.svelte-1dmje9x, .text.svelte-1dmje9x {
    width: calc(50% - 4px)
}

.color.svelte-1dmje9x {
    height: 32px
}

.text.svelte-1dmje9x {
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding)
}

.text.svelte-1dmje9x:focus {
    border: var(--wx-input-border-focus)
}

.color-picker.svelte-xccdpq.svelte-xccdpq {
    position: relative;
    width: var(--wx-input-width)
}

.color.svelte-xccdpq.svelte-xccdpq {
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border-radius: var(--wx-input-border-radius);
    cursor: pointer;
    position: absolute;
    left: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%)
}

input.svelte-xccdpq.svelte-xccdpq {
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    padding-left: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    overflow: hidden;
    text-overflow: ellipsis
}

input.focus.svelte-xccdpq.svelte-xccdpq {
    border: var(--wx-input-border-focus)
}

input.svelte-xccdpq.svelte-xccdpq::-moz-placeholder {
    color: var(--wx-input-placeholder-color)
}

input.svelte-xccdpq.svelte-xccdpq::placeholder {
    color: var(--wx-input-placeholder-color)
}

input[disabled].svelte-xccdpq.svelte-xccdpq {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

input[disabled].svelte-xccdpq.svelte-xccdpq::-moz-placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].svelte-xccdpq.svelte-xccdpq::placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].svelte-xccdpq ~ .color.svelte-xccdpq {
    cursor: not-allowed
}

input.error.svelte-xccdpq.svelte-xccdpq {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

.color-picker.svelte-kstavy.svelte-kstavy {
    position: relative;
    width: var(--wx-input-width)
}

.selected.svelte-kstavy.svelte-kstavy {
    position: absolute;
    left: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%)
}

.colors.svelte-kstavy.svelte-kstavy {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px
}

.color.svelte-kstavy.svelte-kstavy {
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border-radius: var(--wx-input-border-radius);
    cursor: pointer
}

input.svelte-kstavy.svelte-kstavy {
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    padding-left: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    overflow: hidden;
    text-overflow: ellipsis
}

input.focus.svelte-kstavy.svelte-kstavy {
    border: var(--wx-input-border-focus)
}

input.svelte-kstavy.svelte-kstavy::-moz-placeholder {
    color: var(--wx-input-placeholder-color)
}

input.svelte-kstavy.svelte-kstavy::placeholder {
    color: var(--wx-input-placeholder-color)
}

input[disabled].svelte-kstavy.svelte-kstavy {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

input[disabled].svelte-kstavy.svelte-kstavy::-moz-placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].svelte-kstavy.svelte-kstavy::placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].svelte-kstavy ~ .color.svelte-kstavy, input[disabled].svelte-kstavy ~ .empty.svelte-kstavy {
    cursor: not-allowed
}

input.error.svelte-kstavy.svelte-kstavy {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

.empty.svelte-kstavy.svelte-kstavy {
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: linear-gradient(
            to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 1px),
            rgb(255, 0, 0) 50%,
            rgba(0, 0, 0, 0) calc(50% + 1px),
            rgba(0, 0, 0, 0) 100%
    );
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.clear.svelte-kstavy.svelte-kstavy {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border-radius: var(--wx-input-border-radius);
    color: var(--wx-input-icon-color);
    cursor: pointer
}

.clear.svelte-kstavy.svelte-kstavy:hover {
    background: var(--wx-background-hover)
}

.combo.svelte-rf04oq.svelte-rf04oq {
    position: relative;
    width: var(--wx-input-width)
}

input.svelte-rf04oq.svelte-rf04oq {
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer
}

input.svelte-rf04oq.svelte-rf04oq:focus {
    border: var(--wx-input-border-focus)
}

input.svelte-rf04oq.svelte-rf04oq::-moz-placeholder {
    color: var(--wx-input-placeholder-color)
}

input.svelte-rf04oq.svelte-rf04oq::placeholder {
    color: var(--wx-input-placeholder-color)
}

input[disabled].svelte-rf04oq.svelte-rf04oq {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

input[disabled].svelte-rf04oq.svelte-rf04oq::-moz-placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].svelte-rf04oq.svelte-rf04oq::placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].svelte-rf04oq ~ .icon.svelte-rf04oq {
    color: var(--wx-color-font-disabled)
}

input.error.svelte-rf04oq.svelte-rf04oq {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

input.error.svelte-rf04oq ~ .icon.svelte-rf04oq {
    color: var(--wx-color-danger)
}

.icon.svelte-rf04oq.svelte-rf04oq {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.icon.svelte-rf04oq.svelte-rf04oq:before {
    display: block
}

.icon.wxi-close.svelte-rf04oq.svelte-rf04oq {
    pointer-events: all
}

.icon.wxi-close.svelte-rf04oq.svelte-rf04oq:hover {
    color: var(--wx-color-danger)
}

.datepicker.svelte-13w0vs5 {
    position: relative;
    width: var(--wx-input-width)
}

.datepicker.svelte-13w0vs5 {
    position: relative;
    width: var(--wx-input-width)
}

.dropdown.svelte-1iqv09v {
    position: absolute;
    z-index: 5;
    background: var(--wx-popup-background);
    box-shadow: var(--wx-popup-shadow);
    border: var(--wx-popup-border);
    border-radius: var(--wx-popup-border-radius);
    overflow: hidden
}

.top-center.svelte-1iqv09v {
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%) translateY(-2px)
}

.top-start.svelte-1iqv09v {
    top: 0;
    left: 0;
    transform: translateY(-100%) translateY(-2px)
}

.top-end.svelte-1iqv09v {
    top: 0;
    right: 0;
    transform: translateY(-100%) translateY(-2px)
}

.bottom-center.svelte-1iqv09v {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%) translateY(2px)
}

.bottom-start.svelte-1iqv09v {
    bottom: 0;
    left: 0;
    transform: translateY(100%) translateY(2px)
}

.bottom-end.svelte-1iqv09v {
    bottom: 0;
    right: 0;
    transform: translateY(100%) translateY(2px)
}

.left-center.svelte-1iqv09v {
    bottom: 50%;
    left: 0;
    transform: translate(-100%, 50%) translateX(-2px)
}

.left-start.svelte-1iqv09v {
    top: 0;
    left: 0;
    transform: translateX(-100%) translateX(-2px)
}

.left-end.svelte-1iqv09v {
    bottom: 0;
    left: 0;
    transform: translateX(-100%) translateX(-2px)
}

.right-center.svelte-1iqv09v {
    bottom: 50%;
    right: 0;
    transform: translate(100%, 50%) translateX(2px)
}

.right-start.svelte-1iqv09v {
    top: 0;
    right: 0;
    transform: translateX(100%) translateX(2px)
}

.right-end.svelte-1iqv09v {
    bottom: 0;
    right: 0;
    transform: translateX(100%) translateX(2px)
}

.field.svelte-1b27mb4.svelte-1b27mb4 {
    width: var(--wx-field-width);
    max-width: 100%;
    margin-bottom: var(--wx-field-gutter)
}

.field.left.svelte-1b27mb4.svelte-1b27mb4 {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start
}

.field.left.svelte-1b27mb4 > label.svelte-1b27mb4 {
    width: var(--wx-label-width);
    flex-shrink: 0;
    margin: 0 var(--wx-field-gutter) 0 0;
    padding-top: calc(
            (var(--wx-input-height) - var(--wx-label-line-height)) / 2
    );
    text-align: right
}

.field.left.svelte-1b27mb4 > .field-control.svelte-1b27mb4 {
    max-width: calc(100% - var(--wx-label-width) - var(--wx-field-gutter))
}

.field.error.svelte-1b27mb4 label.svelte-1b27mb4 {
    color: var(--wx-color-danger)
}

.field.left.svelte-1b27mb4 .field-control.checkbox.svelte-1b27mb4 {
    padding-top: calc(
            (var(--wx-input-height) - var(--wx-checkbox-height)) / 2
    );
    padding-bottom: calc(
            (var(--wx-input-height) - var(--wx-checkbox-height)) / 2
    )
}

.field.left.svelte-1b27mb4 .field-control.slider.svelte-1b27mb4 {
    padding-top: calc(
            (var(--wx-input-height) - var(--wx-slider-height)) / 2
    );
    padding-bottom: calc(
            (var(--wx-input-height) - var(--wx-slider-height)) / 2
    )
}

.field.left.svelte-1b27mb4 .field-control.switch.svelte-1b27mb4 {
    padding-top: calc(
            (var(--wx-input-height) - var(--wx-switch-height)) / 2
    );
    padding-bottom: calc(
            (var(--wx-input-height) - var(--wx-switch-height)) / 2
    )
}

label.svelte-1b27mb4.svelte-1b27mb4 {
    display: block;
    margin: var(--wx-label-margin);
    padding: var(--wx-label-padding);
    font-family: var(--wx-label-font-family);
    font-size: var(--wx-label-font-size);
    line-height: var(--wx-label-line-height);
    font-weight: var(--wx-label-font-weight);
    color: var(--wx-label-font-color)
}

.field-control.svelte-1b27mb4.svelte-1b27mb4 {
    position: relative;
    width: 100%
}

.icon.svelte-12jhmlp {
    color: var(--wx-color-font-alt);
    cursor: pointer;
    font-size: var(--wx-button-icon-size);
    padding: var(--wx-button-icon-indent);
    line-height: var(--wx-button-line-height);
    display: inline-block
}

.icon.svelte-12jhmlp:hover {
    background-color: var(--wx-background-hover)
}

.modal.svelte-1a9hi0k {
    position: fixed;
    z-index: var(--wx-modal-z-index);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: var(--wx-modal-backdrop);
    text-align: center
}

.window.svelte-1a9hi0k {
    background: var(--wx-modal-background);
    box-shadow: var(--wx-modal-shadow);
    border: var(--wx-modal-border);
    border-radius: var(--wx-modal-border-radius);
    padding: var(--wx-modal-padding);
    min-width: var(--wx-modal-width)
}

.header.svelte-1a9hi0k {
    font-family: var(--wx-modal-header-font-family);
    font-size: var(--wx-modal-header-font-size);
    line-height: var(--wx-modal-header-line-height);
    font-weight: var(--wx-modal-header-font-weight);
    color: var(--wx-modal-header-font-color);
    margin-bottom: var(--wx-modal-gutter)
}

.buttons.svelte-1a9hi0k {
    margin-top: var(--wx-modal-gutter);
    display: flex;
    justify-content: center;
    margin-left: calc(var(--wx-modal-gutter) / -2);
    margin-right: calc(var(--wx-modal-gutter) / -2)
}

.button.svelte-1a9hi0k {
    flex: 1;
    max-width: 50%;
    padding: 0 calc(var(--wx-modal-gutter) / 2)
}

.modal.svelte-vsown2 {
    position: absolute;
    z-index: var(--wx-modal-z-index);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--wx-modal-backdrop);
    display: flex;
    align-items: center;
    justify-content: center
}

.window.svelte-vsown2 {
    background: var(--wx-modal-background);
    box-shadow: var(--wx-modal-shadow);
    border: var(--wx-modal-border);
    border-radius: var(--wx-modal-border-radius);
    min-width: var(--wx-modal-width)
}

.combo.svelte-aegchu.svelte-aegchu {
    position: relative;
    width: var(--wx-input-width)
}

.combo.focus.svelte-aegchu .wrapper.svelte-aegchu {
    border: var(--wx-input-border-focus)
}

.combo.disabled.svelte-aegchu .wrapper.svelte-aegchu {
    border: var(--wx-input-border-disabled);
    background: var(--wx-input-background-disabled)
}

.combo.disabled.svelte-aegchu .tag.svelte-aegchu {
    background: var(--wx-color-disabled);
    color: var(--wx-color-font-disabled)
}

.combo.svelte-aegchu:not(.disabled) .tag.svelte-aegchu {
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    )
}

.combo.disabled.svelte-aegchu input.svelte-aegchu {
    cursor: not-allowed;
    color: var(--wx-color-font-disabled)
}

.combo.disabled.svelte-aegchu .icon.svelte-aegchu {
    color: var(--wx-color-font-disabled)
}

.combo.error.svelte-aegchu .wrapper.svelte-aegchu {
    border-color: var(--wx-color-danger)
}

.combo.error.svelte-aegchu input.svelte-aegchu {
    color: var(--wx-color-danger)
}

.combo.error.svelte-aegchu .icon.svelte-aegchu {
    color: var(--wx-color-danger)
}

.combo.not-empty.svelte-aegchu .tags.svelte-aegchu {
    gap: var(--wx-multicombo-tag-gap);
    padding: var(--wx-multicombo-tag-gap)
}

.combo.not-empty.svelte-aegchu input.svelte-aegchu {
    border-top: var(--wx-input-border)
}

.combo.not-empty.focus.svelte-aegchu input.svelte-aegchu {
    border-top: var(--wx-input-border-focus)
}

.combo.not-empty.disabled.svelte-aegchu input.svelte-aegchu {
    border-top: var(--wx-input-border-disabled)
}

.combo.not-empty.error.svelte-aegchu input.svelte-aegchu {
    border-top-color: var(--wx-color-danger)
}

.wrapper.svelte-aegchu.svelte-aegchu {
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background)
}

.tags.svelte-aegchu.svelte-aegchu {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow: hidden
}

.tag.svelte-aegchu.svelte-aegchu {
    position: relative;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    border: var(--wx-multicombo-tag-border);
    border-radius: var(--wx-multicombo-tag-border-radius);
    background: var(--wx-multicombo-tag-background);
    padding: var(--wx-multicombo-tag-pading)
}

.wxi-close.svelte-aegchu.svelte-aegchu {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    border-radius: var(--wx-input-border-radius);
    color: var(--wx-input-icon-color);
    cursor: pointer
}

.wxi-close.svelte-aegchu.svelte-aegchu:hover {
    color: var(--wx-color-primary)
}

.select.svelte-aegchu.svelte-aegchu {
    position: relative
}

input.svelte-aegchu.svelte-aegchu {
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: transparent;
    border: none;
    border-radius: 0;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer
}

.icon.svelte-aegchu.svelte-aegchu {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.icon.svelte-aegchu.svelte-aegchu:before {
    display: block
}

.message.svelte-1rtnb7d.svelte-1rtnb7d {
    position: relative;
    background: var(--wx-notice-background);
    box-shadow: var(--wx-notice-shadow);
    border: var(--wx-notice-border);
    border-radius: var(--wx-notice-border-radius);
    margin: var(--wx-notice-margin);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    font-family: var(--wx-notice-font-family);
    font-size: var(--wx-notice-font-size);
    line-height: var(--wx-notice-line-height);
    font-weight: var(--wx-notice-font-weight);
    color: var(--wx-notice-font-color);
    width: var(--wx-notice-width)
}

.message.svelte-1rtnb7d.svelte-1rtnb7d:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: var(--wx-notice-type-border-width);
    height: 100%;
    border-top-right-radius: var(--wx-notice-type-border-width);
    border-bottom-right-radius: var(--wx-notice-type-border-width);
    background: var(--wx-notice-type-border-color)
}

.message.svelte-1rtnb7d.svelte-1rtnb7d:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: var(--wx-notice-type-background-opacity);
    background: transparent
}

.text.svelte-1rtnb7d.svelte-1rtnb7d, .button.svelte-1rtnb7d.svelte-1rtnb7d {
    position: relative;
    z-index: 2;
    padding: var(--wx-notice-padding)
}

.text.svelte-1rtnb7d.svelte-1rtnb7d {
    flex-shrink: 0;
    word-wrap: break-word;
    max-width: calc(
            var(--wx-notice-width) - var(--wx-notice-padding) * 2 -
            var(--wx-notice-icon-size)
    )
}

.close.svelte-1rtnb7d.svelte-1rtnb7d {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--wx-notice-icon-size);
    line-height: 1;
    width: var(--wx-notice-icon-size);
    height: var(--wx-notice-icon-size);
    color: var(--wx-notice-type-icon-color);
    cursor: pointer
}

.close.svelte-1rtnb7d.svelte-1rtnb7d:before {
    display: block;
    z-index: 1
}

.close.svelte-1rtnb7d.svelte-1rtnb7d:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 50%
}

.close.svelte-1rtnb7d.svelte-1rtnb7d:hover:after {
    background: var(--wx-background-hover)
}

.message.info.svelte-1rtnb7d.svelte-1rtnb7d, .message.warning.svelte-1rtnb7d.svelte-1rtnb7d, .message.success.svelte-1rtnb7d.svelte-1rtnb7d, .message.danger.svelte-1rtnb7d.svelte-1rtnb7d {
    color: var(--wx-notice-type-font-color)
}

.message.info.svelte-1rtnb7d.svelte-1rtnb7d:before, .message.info.svelte-1rtnb7d.svelte-1rtnb7d:after {
    background: var(--wx-color-info)
}

.message.warning.svelte-1rtnb7d.svelte-1rtnb7d:before, .message.warning.svelte-1rtnb7d.svelte-1rtnb7d:after {
    background: var(--wx-color-warning)
}

.message.success.svelte-1rtnb7d.svelte-1rtnb7d:before, .message.success.svelte-1rtnb7d.svelte-1rtnb7d:after {
    background: var(--wx-color-success)
}

.message.danger.svelte-1rtnb7d.svelte-1rtnb7d:before, .message.danger.svelte-1rtnb7d.svelte-1rtnb7d:after {
    background: var(--wx-color-danger)
}

.message.info.svelte-1rtnb7d .close.svelte-1rtnb7d:after, .message.warning.svelte-1rtnb7d .close.svelte-1rtnb7d:after, .message.success.svelte-1rtnb7d .close.svelte-1rtnb7d:after, .message.danger.svelte-1rtnb7d .close.svelte-1rtnb7d:after {
    opacity: var(--wx-notice-type-close-hover-opacity)
}

.area.svelte-8aeoij {
    position: fixed;
    z-index: var(--wx-notice-z-index);
    top: 0;
    right: 0
}

.pagination.svelte-1o2ew03 {
    display: flex;
    gap: var(--wx-padding);
    align-items: center;
    padding: var(--wx-padding)
}

input.svelte-1o2ew03 {
    display: block;
    width: 50px;
    height: var(--wx-input-height);
    padding: var(--wx-input-padding);
    outline: none;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background)
}

input.svelte-1o2ew03:focus {
    border: var(--wx-input-border-focus)
}

input.svelte-1o2ew03::-moz-placeholder {
    color: var(--wx-input-placeholder-color)
}

input.svelte-1o2ew03::placeholder {
    color: var(--wx-input-placeholder-color)
}

.icon.svelte-1o2ew03 {
    cursor: pointer;
    padding: var(--wx-input-padding);
    border-radius: var(--wx-input-border-radius);
    font-size: var(--wx-icon-size);
    line-height: 1;
    color: var(--wx-color-link)
}

.icon.svelte-1o2ew03:before {
    display: block
}

.icon.svelte-1o2ew03:hover {
    background-color: var(--wx-background-hover)
}

.left.svelte-1o2ew03, .center.svelte-1o2ew03, .right.svelte-1o2ew03 {
    display: flex;
    align-items: center;
    gap: var(--wx-padding)
}

.popup.svelte-3iw5hi {
    position: fixed;
    z-index: var(--wx-popup-z-index);
    background: var(--wx-popup-background);
    box-shadow: var(--wx-popup-shadow);
    border: var(--wx-popup-border);
    border-radius: var(--wx-popup-border-radius);
    overflow: hidden
}

.wx-clone.svelte-nejz1p {
    display: none
}

div.svelte-1kw64uj.svelte-1kw64uj {
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: var(--wx-input-width)
}

input.svelte-1kw64uj.svelte-1kw64uj {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0
}

label.svelte-1kw64uj.svelte-1kw64uj {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    font-family: var(--wx-checkbox-font-family);
    font-size: var(--wx-checkbox-font-size);
    line-height: var(--wx-checkbox-line-height);
    font-weight: var(--wx-checkbox-font-weight);
    color: var(--wx-checkbox-font-color);
    cursor: pointer
}

span.svelte-1kw64uj.svelte-1kw64uj {
    display: block
}

span.svelte-1kw64uj + span.svelte-1kw64uj {
    margin-left: 8px;
    padding-top: calc(
            (var(--wx-checkbox-height) - var(--wx-checkbox-line-height)) / 2
    );
    padding-bottom: calc(
            (var(--wx-checkbox-height) - var(--wx-checkbox-line-height)) / 2
    )
}

span.svelte-1kw64uj.svelte-1kw64uj:first-child {
    position: relative;
    flex-shrink: 0;
    padding-top: calc(
            (var(--wx-checkbox-height) - var(--wx-checkbox-size)) / 2
    );
    padding-bottom: calc(
            (var(--wx-checkbox-height) - var(--wx-checkbox-size)) / 2
    )
}

span.svelte-1kw64uj.svelte-1kw64uj:first-child:before {
    content: "";
    display: block;
    width: var(--wx-checkbox-size);
    height: var(--wx-checkbox-size);
    border: var(--wx-checkbox-border-width) solid var(--wx-checkbox-border-color);
    border-radius: 50%;
    background: var(--wx-input-background)
}

span.svelte-1kw64uj.svelte-1kw64uj:first-child:after {
    content: "";
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(
            var(--wx-checkbox-size) - var(--wx-checkbox-border-width) * 2
    );
    height: calc(
            var(--wx-checkbox-size) - var(--wx-checkbox-border-width) * 2
    );
    border-style: solid;
    border-color: var(--wx-input-background);
    border-width: calc(var(--wx-checkbox-size) * 0.2);
    border-radius: 50%
}

input:checked ~ label.svelte-1kw64uj span.svelte-1kw64uj:first-child:before {
    background: var(--wx-color-primary);
    border-color: transparent
}

input:checked ~ label.svelte-1kw64uj span.svelte-1kw64uj:first-child:after {
    display: block
}

input[disabled].svelte-1kw64uj ~ label.svelte-1kw64uj {
    color: var(--wx-checkbox-border-color-disabled);
    cursor: not-allowed
}

input[disabled]:not(:checked) ~ label.svelte-1kw64uj span.svelte-1kw64uj:first-child:before {
    border-color: var(--wx-checkbox-border-color-disabled)
}

input[disabled]:checked ~ label.svelte-1kw64uj span.svelte-1kw64uj:first-child:before {
    background: var(--wx-checkbox-border-color-disabled)
}

.group.svelte-txfdxf.svelte-txfdxf {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: calc(var(--wx-field-gutter) * -1)
}

.group-item.svelte-txfdxf.svelte-txfdxf {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: var(--wx-field-gutter)
}

.group.inline.svelte-txfdxf .group-item.svelte-txfdxf {
    flex: none;
    padding-right: var(--wx-field-gutter)
}

.group.grid.svelte-txfdxf .group-item.svelte-txfdxf {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: var(--wx-field-gutter)
}

.calendars.svelte-y4vzbo {
    display: flex;
    padding-bottom: var(--wx-calendar-padding)
}

.half.svelte-y4vzbo {
    flex: 1
}

.select.svelte-1ta4c5y.svelte-1ta4c5y {
    position: relative;
    outline: none;
    width: var(--wx-input-width);
    min-height: var(--wx-input-height);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background);
    cursor: pointer
}

.select.svelte-1ta4c5y.svelte-1ta4c5y:focus {
    border: var(--wx-input-border-focus)
}

.select.disabled.svelte-1ta4c5y.svelte-1ta4c5y {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    background: var(--wx-input-background-disabled)
}

.select.disabled.svelte-1ta4c5y .label.svelte-1ta4c5y {
    color: var(--wx-color-font-disabled)
}

.select.disabled.svelte-1ta4c5y .icon.svelte-1ta4c5y {
    color: var(--wx-color-font-disabled)
}

.select.error.svelte-1ta4c5y.svelte-1ta4c5y {
    border-color: var(--wx-color-danger)
}

.select.error.svelte-1ta4c5y .label.svelte-1ta4c5y {
    color: var(--wx-color-danger)
}

.select.error.svelte-1ta4c5y .icon.svelte-1ta4c5y {
    color: var(--wx-color-danger)
}

.label.svelte-1ta4c5y.svelte-1ta4c5y {
    display: block;
    width: 100%;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    overflow: hidden
}

.placeholder.svelte-1ta4c5y.svelte-1ta4c5y {
    color: var(--wx-input-placeholder-color)
}

.icon.svelte-1ta4c5y.svelte-1ta4c5y {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.icon.svelte-1ta4c5y.svelte-1ta4c5y:before {
    display: block
}

.nowrap.svelte-1ta4c5y .label.svelte-1ta4c5y {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

div.svelte-otciin.svelte-otciin {
    display: inline-flex;
    flex-wrap: nowrap;
    background: var(--wx-segmented-background);
    border: var(--wx-segmented-border);
    border-radius: var(--wx-segmented-border-radius);
    padding: var(--wx-segmented-padding);
    max-width: 100%
}

button.svelte-otciin.svelte-otciin {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    text-decoration: none;
    text-align: center;
    letter-spacing: normal;
    text-transform: var(--wx-button-text-transform);
    font-family: var(--wx-button-font-family);
    font-size: var(--wx-button-font-size);
    line-height: var(--wx-button-line-height);
    font-weight: var(--wx-button-font-weigth);
    padding: var(--wx-button-padding);
    border: var(--wx-button-border);
    border-radius: var(--wx-segmented-border-radius);
    background: transparent;
    color: var(--wx-button-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

button.svelte-otciin.svelte-otciin, button.svelte-otciin.svelte-otciin:focus, button.svelte-otciin.svelte-otciin:active {
    outline: none
}

button.svelte-otciin + button.svelte-otciin {
    margin-left: var(--wx-segmented-padding)
}

button.svelte-otciin.svelte-otciin:hover {
    background: var(--wx-segmented-background-hover)
}

button.selected.svelte-otciin.svelte-otciin, button.selected.svelte-otciin.svelte-otciin:hover, button.selected.svelte-otciin.svelte-otciin:focus {
    background: #FFD9D9;
    color: #FF5555;
    cursor: default
}

.icon.svelte-otciin.svelte-otciin {
    position: relative;
    font-size: var(--wx-button-icon-size);
    line-height: 1;
    height: var(--wx-button-line-height);
    opacity: 0.7
}

.icon.svelte-otciin.svelte-otciin:before {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}

.icon.only.svelte-otciin.svelte-otciin {
    opacity: 1
}

.icon.svelte-otciin + .label.svelte-otciin {
    margin-left: 4px
}

div.svelte-1355qs9.svelte-1355qs9 {
    position: relative;
    width: var(--wx-input-width)
}

select.svelte-1355qs9.svelte-1355qs9 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: var(--wx-input-height);
    outline: none;
    background: var(--wx-input-background);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer
}

select.svelte-1355qs9.svelte-1355qs9:focus {
    border: var(--wx-input-border-focus)
}

select[disabled].svelte-1355qs9.svelte-1355qs9 {
    cursor: not-allowed;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

select[disabled].svelte-1355qs9 ~ .placeholder.svelte-1355qs9 {
    color: var(--wx-color-font-disabled)
}

select[disabled].svelte-1355qs9 ~ .icon.svelte-1355qs9 {
    color: var(--wx-color-font-disabled)
}

select.error.svelte-1355qs9.svelte-1355qs9 {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

select.error.svelte-1355qs9 option.svelte-1355qs9 {
    color: var(--wx-input-font-color)
}

select.error.svelte-1355qs9 ~ .icon.svelte-1355qs9 {
    color: var(--wx-color-danger)
}

.placeholder.svelte-1355qs9.svelte-1355qs9 {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border: var(--wx-input-border);
    border-color: transparent;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-placeholder-color);
    padding: var(--wx-input-padding);
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    );
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    cursor: pointer;
    pointer-events: none
}

.icon.svelte-1355qs9.svelte-1355qs9 {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.icon.svelte-1355qs9.svelte-1355qs9:before {
    display: block
}

.window.svelte-djiewd {
    position: absolute;
    z-index: var(--wx-popup-z-index);
    background: var(--wx-modal-background);
    box-shadow: var(--wx-modal-shadow);
    border: var(--wx-modal-border);
    border-radius: var(--wx-modal-border-radius);
    min-width: var(--wx-modal-width);
    /*height: 100vh*/
}

.window.pos-right.svelte-djiewd {
    right: 0;
    top: 0
}

.slider.svelte-yw8n7a.svelte-yw8n7a {
    width: var(--wx-input-width)
}

label.svelte-yw8n7a.svelte-yw8n7a {
    display: block;
    margin: var(--wx-slider-label-margin);
    font-family: var(--wx-slider-label-font-family);
    font-size: var(--wx-slider-label-font-size);
    line-height: var(--wx-slider-label-line-height);
    font-weight: var(--wx-slider-label-font-weight);
    color: var(--wx-slider-label-font-color)
}

.slider.svelte-yw8n7a div.svelte-yw8n7a {
    padding: calc(
            (var(--wx-slider-height) - var(--wx-slider-track-height)) / 2
    ) 0
}

input.svelte-yw8n7a.svelte-yw8n7a {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: var(--wx-slider-track-height);
    background: var(--wx-slider-background);
    border: none;
    border-radius: var(--wx-slider-track-border-radius);
    margin: 0
}

input.svelte-yw8n7a.svelte-yw8n7a:focus {
    outline: none
}

input.svelte-yw8n7a.svelte-yw8n7a::-webkit-slider-runnable-track {
    margin: 0;
    width: 100%;
    height: var(--wx-slider-track-height);
    border: none;
    border-radius: var(--wx-slider-track-border-radius);
    cursor: pointer;
    background: transparent
}

input.svelte-yw8n7a.svelte-yw8n7a::-webkit-slider-thumb {
    margin-top: calc(
            (var(--wx-slider-track-height) - var(--wx-slider-thumb-size)) / 2
    );
    width: var(--wx-slider-thumb-size);
    height: var(--wx-slider-thumb-size);
    background: var(--wx-slider-primary);
    border: var(--wx-slider-thumb-border);
    box-shadow: var(--wx-slider-thumb-shadow);
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none
}

input.svelte-yw8n7a.svelte-yw8n7a::-moz-range-track {
    margin: 0;
    width: 100%;
    height: var(--wx-slider-track-height);
    border: none;
    border-radius: var(--wx-slider-track-border-radius);
    cursor: pointer;
    background: transparent
}

input.svelte-yw8n7a.svelte-yw8n7a::-moz-range-thumb {
    margin-top: calc(
            (var(--wx-slider-track-height) - var(--wx-slider-thumb-size)) / 2
    );
    width: var(--wx-slider-thumb-size);
    height: var(--wx-slider-thumb-size);
    background: var(--wx-slider-primary);
    border: var(--wx-slider-thumb-border);
    border-radius: 50%;
    cursor: pointer;
    -moz-appearance: none;
    appearance: none
}

input.svelte-yw8n7a.svelte-yw8n7a::-moz-range-progress {
    background-color: var(--wx-slider-primary);
    height: var(--wx-slider-track-height);
    border-top-left-radius: var(--wx-slider-track-border-radius);
    border-bottom-left-radius: var(--wx-slider-track-border-radius)
}

input.svelte-yw8n7a.svelte-yw8n7a::-ms-track {
    color: transparent;
    margin: 0;
    width: 100%;
    height: var(--wx-slider-track-height);
    border: none;
    border-radius: var(--wx-slider-track-border-radius);
    cursor: pointer;
    background: transparent
}

input.svelte-yw8n7a.svelte-yw8n7a::-ms-fill-lower {
    background: var(--wx-slider-primary);
    border: 0.2px solid var(--wx-slider-primary);
    border-radius: var(--wx-slider-track-border-radius)
}

input.svelte-yw8n7a.svelte-yw8n7a::-ms-fill-upper {
    background: var(--wx-slider-background);
    border: 0.2px solid var(--wx-slider-background);
    border-radius: var(--wx-slider-track-border-radius)
}

input.svelte-yw8n7a.svelte-yw8n7a::-ms-thumb {
    margin-top: calc(
            (var(--wx-slider-track-height) - var(--wx-slider-thumb-size)) / 2
    );
    width: var(--wx-slider-thumb-size);
    height: var(--wx-slider-thumb-size);
    background: var(--wx-slider-primary);
    border: var(--wx-slider-thumb-border);
    border-radius: 50%;
    cursor: pointer;
    appearance: none
}

input.svelte-yw8n7a.svelte-yw8n7a:focus::-ms-fill-lower {
    background: var(--wx-slider-primary)
}

input.svelte-yw8n7a.svelte-yw8n7a:focus::-ms-fill-upper {
    background: var(--wx-slider-background)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a {
    cursor: not-allowed;
    background: var(--wx-color-disabled)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-webkit-slider-runnable-track {
    cursor: not-allowed
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-webkit-slider-thumb {
    cursor: not-allowed;
    background: var(--wx-background);
    border: var(--wx-slider-thumb-border-disabled)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-moz-range-track {
    cursor: not-allowed
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-moz-range-thumb {
    cursor: not-allowed;
    background: var(--wx-background);
    border: var(--wx-slider-thumb-border-disabled)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-moz-range-progress {
    cursor: not-allowed;
    background: var(--wx-color-disabled)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-ms-track {
    cursor: not-allowed
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-ms-fill-lower {
    background: var(--wx-color-disabled);
    border-color: var(--wx-color-disabled)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-ms-fill-upper {
    background: var(--wx-color-disabled);
    border-color: var(--wx-color-disabled)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a::-ms-thumb {
    cursor: not-allowed;
    background: var(--wx-background);
    border: var(--wx-slider-thumb-border-disabled)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a:focus::-ms-fill-lower {
    background: var(--wx-color-disabled)
}

input[disabled].svelte-yw8n7a.svelte-yw8n7a:focus::-ms-fill-upper {
    background: var(--wx-color-disabled)
}

label.svelte-wlyb50.svelte-wlyb50 {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    cursor: default
}

input.svelte-wlyb50.svelte-wlyb50 {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0
}

span.svelte-wlyb50.svelte-wlyb50 {
    display: block;
    position: relative;
    width: var(--wx-switch-width);
    height: var(--wx-switch-height);
    border: var(--wx-switch-border-width) solid var(--wx-switch-border-color);
    border-radius: calc(var(--wx-switch-height) / 2);
    background-color: var(--wx-switch-background);
    transition: background-color 0.4s ease;
    cursor: pointer;
    overflow: hidden
}

span.svelte-wlyb50.svelte-wlyb50:before {
    content: "";
    position: absolute;
    left: var(--wx-switch-thumb-offset);
    top: var(--wx-switch-thumb-offset);
    height: calc(
            var(--wx-switch-height) - var(--wx-switch-thumb-offset) * 2 -
            var(--wx-switch-border-width) * 2
    );
    width: calc(
            var(--wx-switch-height) - var(--wx-switch-thumb-offset) * 2 -
            var(--wx-switch-border-width) * 2
    );
    border: var(--wx-switch-thumb-border);
    border-radius: 50%;
    background: var(--wx-switch-thumb-background);
    box-shadow: var(--wx-switch-thumb-shadow);
    transition: transform 0.4s ease
}

input.svelte-wlyb50:checked ~ span.svelte-wlyb50 {
    background-color: var(--wx-switch-primary)
}

input.svelte-wlyb50:checked ~ span.svelte-wlyb50:before {
    transform: translateX(
            calc(var(--wx-switch-width) - var(--wx-switch-height))
    )
}

input[disabled].svelte-wlyb50 ~ span.svelte-wlyb50 {
    background-color: var(--wx-color-disabled);
    border-color: var(--wx-switch-border-color-disabled);
    cursor: not-allowed
}

input[disabled].svelte-wlyb50 ~ span.svelte-wlyb50:before {
    border: var(--wx-switch-thumb-border-disabled);
    background: var(--wx-switch-thumb-background-disabled)
}

div.svelte-178zhk.svelte-178zhk {
    display: inline-flex;
    flex-wrap: nowrap
}

button.svelte-178zhk.svelte-178zhk {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    letter-spacing: normal;
    text-transform: var(--wx-button-text-transform);
    font-family: var(--wx-button-font-family);
    font-size: var(--wx-button-font-size);
    line-height: var(--wx-button-line-height);
    font-weight: var(--wx-button-font-weigth);
    padding: var(--wx-button-padding);
    border: var(--wx-button-border);
    border-width: var(--wx-tabs-border-width);
    border-radius: 0;
    background: var(--wx-tabs-background);
    color: var(--wx-button-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    min-width: var(--wx-tabs-cell-min-width);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

button.svelte-178zhk + button.svelte-178zhk:before {
    content: "";
    display: block;
    position: absolute;
    left: calc(
            var(--wx-tabs-border-width) * -1 - var(--wx-tabs-divider-width) / 2
    );
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: calc(
            var(--wx-tabs-border-width) * 2 + var(--wx-tabs-divider-height)
    );
    border-left: var(--wx-tabs-divider-width) solid var(--wx-tabs-divider-color)
}

button.svelte-178zhk.svelte-178zhk:first-child {
    border-top-left-radius: var(--wx-tabs-border-radius);
    border-bottom-left-radius: var(--wx-tabs-border-radius)
}

button.svelte-178zhk.svelte-178zhk:last-child {
    border-top-right-radius: var(--wx-tabs-border-radius);
    border-bottom-right-radius: var(--wx-tabs-border-radius)
}

button.svelte-178zhk.svelte-178zhk, button.svelte-178zhk.svelte-178zhk:focus, button.svelte-178zhk.svelte-178zhk:active {
    outline: none
}

button.svelte-178zhk.svelte-178zhk:hover {
    background: var(--wx-tabs-background-hover)
}

button.active.svelte-178zhk.svelte-178zhk, button.active.svelte-178zhk.svelte-178zhk:hover, button.active.svelte-178zhk.svelte-178zhk:focus {
    background: var(--wx-tabs-active-background);
    color: var(--wx-tabs-active-color);
    cursor: default
}

button.active.svelte-178zhk.svelte-178zhk:before {
    display: none
}

button.active.svelte-178zhk + button.svelte-178zhk:before {
    display: none
}

button.active.svelte-178zhk.svelte-178zhk:after, button.svelte-178zhk.svelte-178zhk:hover:after {
    content: "";
    display: block;
    position: absolute;
    left: 13px;
    height: 0;
    width: calc(100% - 26px)
}

button.active.svelte-178zhk.svelte-178zhk:after {
    border-bottom: 2px solid var(--wx-tabs-active-border)
}

button.svelte-178zhk.svelte-178zhk:not(.active):hover:after {
    border-bottom: 2px solid var(--wx-tabs-hover-border)
}

.top.svelte-178zhk button.active.svelte-178zhk:after, .top.svelte-178zhk button.svelte-178zhk:hover:after {
    top: 100%
}

.bottom.svelte-178zhk button.active.svelte-178zhk:after, .bottom.svelte-178zhk button.svelte-178zhk:hover:after {
    top: 0
}

.icon.svelte-178zhk.svelte-178zhk {
    position: relative;
    font-size: var(--wx-button-icon-size);
    line-height: 1;
    height: var(--wx-button-line-height);
    opacity: 0.7
}

.icon.svelte-178zhk.svelte-178zhk:before {
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%)
}

.icon.only.svelte-178zhk.svelte-178zhk {
    opacity: 1
}

.icon.svelte-178zhk + .label.svelte-178zhk {
    margin-left: 4px
}

.input.svelte-1q3ff95.svelte-1q3ff95 {
    position: relative;
    width: var(--wx-input-width)
}

.input.disabled.svelte-1q3ff95 .icon.svelte-1q3ff95 {
    color: var(--wx-color-font-disabled)
}

.input.error.svelte-1q3ff95 .icon.svelte-1q3ff95 {
    color: var(--wx-color-danger)
}

.icon.svelte-1q3ff95.svelte-1q3ff95 {
    position: absolute;
    right: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.icon.svelte-1q3ff95.svelte-1q3ff95:before {
    display: block
}

.wx-icon-left.svelte-1q3ff95 .icon.svelte-1q3ff95 {
    right: auto;
    left: var(--wx-input-icon-indent)
}

input.svelte-1q3ff95.svelte-1q3ff95 {
    display: block;
    width: var(--wx-input-width);
    height: var(--wx-input-height);
    max-width: 100%;
    padding: var(--wx-input-padding);
    outline: none;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    text-align: var(--wx-input-text-align);
    color: var(--wx-input-font-color);
    border: var(--wx-input-border);
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-input-background);
    overflow: hidden;
    text-overflow: ellipsis
}

input.svelte-1q3ff95.svelte-1q3ff95:focus {
    border: var(--wx-input-border-focus)
}

input.svelte-1q3ff95.svelte-1q3ff95::-moz-placeholder {
    color: var(--wx-input-placeholder-color)
}

input.svelte-1q3ff95.svelte-1q3ff95::placeholder {
    color: var(--wx-input-placeholder-color)
}

.wx-icon-left.svelte-1q3ff95 input.svelte-1q3ff95 {
    padding-left: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    )
}

.wx-icon-right.svelte-1q3ff95 input.svelte-1q3ff95 {
    padding-right: calc(
            var(--wx-input-icon-size) + var(--wx-input-icon-indent) * 2
    )
}

input[disabled].svelte-1q3ff95.svelte-1q3ff95 {
    cursor: not-allowed !important;
    border: var(--wx-input-border-disabled);
    color: var(--wx-color-font-disabled);
    background: var(--wx-input-background-disabled)
}

input[disabled].svelte-1q3ff95.svelte-1q3ff95::-moz-placeholder {
    color: var(--wx-color-font-disabled)
}

input[disabled].svelte-1q3ff95.svelte-1q3ff95::placeholder {
    color: var(--wx-color-font-disabled)
}

.error.svelte-1q3ff95 input.svelte-1q3ff95 {
    border-color: var(--wx-color-danger);
    color: var(--wx-color-danger)
}

.title.svelte-1q3ff95 input.svelte-1q3ff95 {
    border: 1px solid transparent;
    font-weight: var(--wx-font-weight-md);
    font-size: var(--wx-font-size-md);
    line-height: var(--wx-line-height-md);
    color: var(--wx-color-secondary-font);
    margin-left: -8px;
    width: calc(100% + 8px)
}

.title.svelte-1q3ff95:focus:not([disabled]) input.svelte-1q3ff95 {
    border: var(--wx-input-border-focus)
}

.title.svelte-1q3ff95:hover:not([disabled]) input.svelte-1q3ff95 {
    border: var(--wx-input-border-focus)
}

.timepicker.svelte-1i68kyj {
    position: relative;
    width: var(--wx-input-width)
}

.wrapper.svelte-1i68kyj {
    padding: 10px 20px
}

.timer.svelte-1i68kyj {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px
}

.digit.svelte-1i68kyj {
    display: block;
    width: 50px;
    height: 38px;
    border: none;
    border-bottom: var(--wx-input-border);
    font-family: var(--wx-input-font-family);
    font-size: 24px;
    line-height: 28px;
    padding: 5px;
    text-align: center;
    color: var(--wx-input-font-color);
    background-color: transparent;
    outline: none
}

.digit.svelte-1i68kyj:focus {
    border-bottom: var(--wx-input-border-focus)
}

.separator.svelte-1i68kyj {
    font-size: 24px;
    line-height: 38px
}

button.svelte-1tj7yhm {
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    text-align: center;
    letter-spacing: normal;
    font-family: var(--wx-calendar-controls-font-family);
    font-size: var(--wx-calendar-controls-font-size);
    line-height: var(--wx-calendar-controls-line-height);
    font-weight: var(--wx-calendar-controls-font-weight);
    text-transform: none;
    padding: 0;
    border: none;
    border-radius: 0;
    background: transparent;
    color: var(--wx-calendar-controls-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

button.svelte-1tj7yhm, button.svelte-1tj7yhm:focus, button.svelte-1tj7yhm:active {
    outline: none
}

button.svelte-1tj7yhm:active {
    opacity: 0.8
}

.years.svelte-ia304r {
    display: flex;
    flex-wrap: wrap;
    margin: var(--wx-calendar-gap)
}

.year.svelte-ia304r {
    flex: 0 0 calc(100% / 4 - var(--wx-calendar-gap) * 2);
    max-width: calc(100% / 4 - var(--wx-calendar-gap) * 2);
    margin: calc(var(--wx-calendar-gap) * 2) var(--wx-calendar-gap);
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: var(--wx-calendar-cell-size);
    border-radius: var(--wx-calendar-border-radius)
}

.year.current.svelte-ia304r {
    background: var(--wx-color-primary);
    color: var(--wx-color-primary-font)
}

.year.svelte-ia304r:not(.current):hover {
    background-color: var(--wx-background-hover)
}

.prev-decade.svelte-ia304r, .next-decade.svelte-ia304r {
    color: var(--wx-color-font-disabled)
}

.buttons.svelte-ia304r {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: var(--wx-calendar-gap)
}

.header.svelte-sd1wqc {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: calc(var(--wx-calendar-gap) * 2);
    font-size: var(--wx-calendar-header-font-size);
    line-height: var(--wx-calendar-header-line-height);
    font-weight: var(--wx-calendar-header-font-weight)
}

.spacer.svelte-sd1wqc, .pager.svelte-sd1wqc {
    width: var(--wx-calendar-cell-size);
    height: var(--wx-calendar-cell-size);
    flex-shrink: 0
}

.pager.svelte-sd1wqc {
    cursor: pointer;
    border-radius: 50%;
    line-height: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: var(--wx-calendar-icon-color);
    font-size: var(--wx-calendar-icon-size)
}

.pager.svelte-sd1wqc:before {
    display: block
}

.pager.svelte-sd1wqc:hover {
    background-color: var(--wx-background-hover)
}

.label.svelte-sd1wqc {
    flex: 0 0 calc(100% - var(--wx-calendar-cell-size) * 2);
    max-width: calc(100% - var(--wx-calendar-cell-size) * 2);
    text-align: center;
    color: var(--wx-color-link);
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.weekdays.svelte-1al976d {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: calc(var(--wx-calendar-gap) * 1.5)
}

.weekday.svelte-1al976d {
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
    font-size: var(--wx-font-size-sm);
    line-height: var(--wx-line-height-sm);
    color: var(--wx-color-font-alt);
    text-align: center
}

.days.svelte-1al976d {
    display: flex;
    flex-wrap: wrap
}

.day.svelte-1al976d {
    border-radius: var(--wx-calendar-border-radius);
    flex: 0 0 calc(100% / 7);
    max-width: calc(100% / 7);
    height: calc(
            var(--wx-calendar-cell-size) - var(--wx-calendar-line-gap) * 2
    );
    margin: calc(var(--wx-calendar-line-gap) / 2) 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center
}

.day.svelte-1al976d:not(.out):not(.selected) {
    cursor: pointer
}

.day.svelte-1al976d:not(.out):not(.selected):hover {
    background: var(--wx-background-hover)
}

.day.out.svelte-1al976d {
    color: var(--wx-color-font-disabled)
}

.day.selected.svelte-1al976d:not(.out) {
    background: var(--wx-color-primary);
    color: var(--wx-color-primary-font)
}

.day.selected.left.svelte-1al976d:not(.out) {
    border-radius: calc(var(--wx-calendar-border-radius)) 0 0 calc(var(--wx-calendar-border-radius))
}

.day.selected.right.svelte-1al976d:not(.out) {
    border-radius: 0 calc(var(--wx-calendar-border-radius)) calc(var(--wx-calendar-border-radius)) 0
}

.day.inrange.svelte-1al976d:not(.out) {
    border-radius: 0;
    background: var(--wx-color-primary-selected)
}

.day.weekend.svelte-1al976d:not(.selected):not(.out) {
    color: var(--wx-color-primary)
}

.day.inactive.svelte-1al976d {
    pointer-events: none
}

.calendar.svelte-14q6rsg.svelte-14q6rsg {
    height: auto;
    width: 100%;
    padding: var(--wx-calendar-padding);
    cursor: default;
    font-family: var(--wx-calendar-font-family);
    font-size: var(--wx-calendar-font-size);
    line-height: var(--wx-calendar-line-height);
    font-weight: var(--wx-calendar-font-weight);
    color: var(--wx-calendar-font-color)
}

.calendar.part.svelte-14q6rsg.svelte-14q6rsg {
    padding-bottom: 0
}

.wrap.svelte-14q6rsg.svelte-14q6rsg {
    width: calc(var(--wx-calendar-cell-size) * 7);
    margin: 0 auto
}

.buttons.svelte-14q6rsg.svelte-14q6rsg {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    margin-top: calc(var(--wx-calendar-gap) * 2)
}

.button-item.svelte-14q6rsg + .button-item.svelte-14q6rsg {
    margin-left: calc(var(--wx-calendar-gap) * 3)
}

.months.svelte-zfj0z0 {
    display: flex;
    flex-wrap: wrap;
    margin: var(--wx-calendar-gap)
}

.month.svelte-zfj0z0 {
    flex: 0 0 calc(100% / 4 - var(--wx-calendar-gap) * 2);
    max-width: calc(100% / 4 - var(--wx-calendar-gap) * 2);
    margin: calc(var(--wx-calendar-gap) * 2) var(--wx-calendar-gap);
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: var(--wx-calendar-cell-size);
    border-radius: var(--wx-calendar-border-radius)
}

.month.current.svelte-zfj0z0 {
    background: var(--wx-color-primary);
    color: var(--wx-color-primary-font)
}

.month.svelte-zfj0z0:not(.current):hover {
    background-color: var(--wx-background-hover)
}

.buttons.svelte-zfj0z0 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: var(--wx-calendar-gap)
}

.list.svelte-1kld9sh {
    max-height: 250px;
    overflow-y: auto
}

.item.svelte-1kld9sh {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-family: var(--wx-input-font-family);
    font-size: var(--wx-input-font-size);
    line-height: var(--wx-input-line-height);
    font-weight: var(--wx-input-font-weigth);
    color: var(--wx-input-font-color);
    padding: var(--wx-input-padding);
    cursor: pointer
}

.item.focus.svelte-1kld9sh {
    background: var(--wx-background-hover)
}

.no-data.svelte-1kld9sh {
    padding: var(--wx-input-padding)
}

.wx-material-theme {
    --wx-color-primary: #FF5555;
    --wx-color-primary-selected: #d5e6ff;
    --wx-color-primary-font: #fff;
    --wx-color-secondary: rgba(0, 0, 0, 0.04);
    --wx-color-secondary-hover: rgba(0, 0, 0, 0.1);
    --wx-color-secondary-font: rgba(0, 0, 0, 0.7);
    --wx-color-secondary-font-hover: rgba(0, 0, 0, 0.7);
    --wx-color-secondary-border: transparent;
    --wx-color-secondary-border-disabled: transparent;
    --wx-color-success: #00d19a;
    --wx-color-warning: #ffc975;
    --wx-color-info: #37a9ef;
    --wx-color-danger: #ff5252;
    --wx-color-disabled: #ededed;
    --wx-color-font: rgba(0, 0, 0, 0.7);
    --wx-color-font-alt: rgba(0, 0, 0, 0.5);
    --wx-color-font-disabled: rgba(0, 0, 0, 0.3);
    --wx-color-link: var(--wx-color-primary);
    --wx-background: #fff;
    --wx-background-alt: #f7f7f7;
    --wx-background-hover:#FFE9E9;
    --wx-font-family: Roboto, Arial, Helvetica, sans-serif;
    --wx-font-size: 14px;
    --wx-line-height: 20px;
    --wx-font-size-md: 16px;
    --wx-line-height-md: 24px;
    --wx-font-size-hd: 16px;
    --wx-line-height-hd: 30px;
    --wx-font-size-sm: 12px;
    --wx-line-height-sm: 16px;
    --wx-font-weight: 400;
    --wx-font-weight-md: 500;
    --wx-font-weight-b: 700;
    --wx-icon-color: var(--wx-color-font-alt);
    --wx-icon-size: var(--wx-line-height);
    --wx-border: 1px solid #dcdcdc;
    --wx-border-radius: 4px;
    --wx-radius-medium: var(--wx-border-radius);
    --wx-border-light: none;
    --wx-border-medium: 1px solid #eaedf5;
    --wx-shadow-light: 0px 4px 20px rgba(44, 47, 60, 0.12);
    --wx-shadow-medium: 0px 2px 4px rgba(0, 0, 0, 0.15);
    --wx-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    --wx-box-shadow-strong: 0px 2px 5px rgba(0, 0, 0, 0.3);
    --wx-padding: 8px;
    --wx-field-gutter: 16px;
    --wx-field-width: 400px;
    --wx-input-font-family: var(--wx-font-family);
    --wx-input-font-size: var(--wx-font-size);
    --wx-input-line-height: var(--wx-line-height);
    --wx-input-font-weigth: var(--wx-font-weight);
    --wx-input-text-align: left;
    --wx-input-font-color: var(--wx-color-font);
    --wx-input-background: var(--wx-background);
    --wx-input-background-disabled: var(--wx-background);
    --wx-input-placeholder-color: var(--wx-color-font-alt);
    --wx-input-border: var(--wx-border);
    --wx-input-border-focus: 1px solid rgba(0, 0, 0, 0.5);
    --wx-input-border-disabled: 1px solid var(--wx-color-disabled);
    --wx-input-border-radius: var(--wx-border-radius);
    --wx-input-height: 32px;
    --wx-input-width: 100%;
    --wx-input-padding: 5px 8px;
    --wx-input-icon-indent: 6px;
    --wx-input-icon-color: var(--wx-icon-color);
    --wx-input-icon-size: var(--wx-icon-size);
    --wx-multicombo-tag-gap: 4px;
    --wx-multicombo-tag-border: none;
    --wx-multicombo-tag-border-radius: var(--wx-input-border-radius);
    --wx-multicombo-tag-pading: 2px 8px;
    --wx-multicombo-tag-background: var(--wx-background-alt);
    --wx-checkbox-height: var(--wx-line-height);
    --wx-checkbox-size: 18px;
    --wx-checkbox-border-width: 2px;
    --wx-checkbox-border-color: var(--wx-color-font-alt);
    --wx-checkbox-border-color-disabled: var(--wx-color-font-disabled);
    --wx-checkbox-border-radius: var(--wx-input-border-radius);
    --wx-checkbox-font-family: var(--wx-font-family);
    --wx-checkbox-font-size: var(--wx-font-size);
    --wx-checkbox-line-height: var(--wx-line-height);
    --wx-checkbox-font-weight: var(--wx-font-weight);
    --wx-checkbox-font-color: var(--wx-color-font);
    --wx-label-width: 80px;
    --wx-label-margin: 0 0 8px;
    --wx-label-padding: 0;
    --wx-label-font-family: var(--wx-font-family);
    --wx-label-font-size: var(--wx-font-size);
    --wx-label-line-height: var(--wx-line-height);
    --wx-label-font-weight: var(--wx-font-weight-md);
    --wx-label-font-color: var(--wx-color-font);
    --wx-button-font-family: var(--wx-font-family);
    --wx-button-font-size: var(--wx-font-size);
    --wx-button-line-height: var(--wx-line-height);
    --wx-button-font-weigth: var(--wx-font-weight-md);
    --wx-button-text-transform: none;
    --wx-button-font-color: var(--wx-color-font);
    --wx-button-danger-font-color: #fff;
    --wx-button-background: var(--wx-background-alt);
    --wx-button-border: 1px solid transparent;
    --wx-button-border-radius: var(--wx-border-radius);
    --wx-button-height: 32px;
    --wx-button-padding: 5px 20px;
    --wx-button-icon-indent: 7px;
    --wx-button-icon-size: 16px;
    --wx-segmented-background: var(--wx-background-alt);
    --wx-segmented-background-hover: var(--wx-background-hover);
    --wx-segmented-border: var(--wx-border);
    --wx-segmented-border-radius: var(--wx-border-radius);
    --wx-segmented-padding: 3px;
    --wx-tabs-background: var(--wx-background-alt);
    --wx-tabs-background-hover: var(--wx-background-hover);
    --wx-tabs-hover-border: transparent;
    --wx-tabs-border-width: 1px;
    --wx-tabs-border-radius: var(--wx-border-radius);
    --wx-tabs-divider-width: 1px;
    --wx-tabs-divider-height: 100%;
    --wx-tabs-divider-color: #dfdfdf;
    --wx-tabs-cell-min-width: 100px;
    --wx-tabs-active-background: var(--wx-color-primary);
    --wx-tabs-active-color: var(--wx-color-primary-font);
    --wx-tabs-active-border: transparent;
    --wx-slider-height: 16px;
    --wx-slider-primary: var(--wx-color-primary);
    --wx-slider-background: #dfdfdf;
    --wx-slider-track-height: 4px;
    --wx-slider-track-border-radius: 2px;
    --wx-slider-thumb-size: var(--wx-slider-height);
    --wx-slider-thumb-border: 2px solid var(--wx-background);
    --wx-slider-thumb-border-disabled: none;
    --wx-slider-thumb-shadow: var(--wx-box-shadow-strong);
    --wx-slider-label-margin: 0 0 5px;
    --wx-slider-label-font-family: var(--wx-font-family);
    --wx-slider-label-font-size: var(--wx-font-size);
    --wx-slider-label-line-height: var(--wx-line-height);
    --wx-slider-label-font-weight: var(--wx-font-weight-md);
    --wx-slider-label-font-color: var(--wx-color-font);
    --wx-switch-primary: var(--wx-color-primary);
    --wx-switch-background: rgba(0, 0, 0, 0.3);
    --wx-switch-width: 44px;
    --wx-switch-height: 22px;
    --wx-switch-border-width: 1px;
    --wx-switch-border-color: transparent;
    --wx-switch-border-color-disabled: #dfdfdf;
    --wx-switch-thumb-offset: 1px;
    --wx-switch-thumb-border: none;
    --wx-switch-thumb-border-disabled: none;
    --wx-switch-thumb-background: var(--wx-background);
    --wx-switch-thumb-background-disabled: var(--wx-background);
    --wx-switch-thumb-shadow: var(--wx-box-shadow-strong);
    --wx-popup-z-index: 100;
    --wx-popup-background: var(--wx-background);
    --wx-popup-shadow: var(--wx-shadow-light);
    --wx-popup-border: none;
    --wx-popup-border-radius: var(--wx-border-radius);
    --wx-modal-z-index: 1000;
    --wx-modal-background: var(--wx-background);
    --wx-modal-shadow: var(--wx-shadow-medium);
    --wx-modal-border: none;
    --wx-modal-border-radius: var(--wx-border-radius);
    --wx-modal-width: 280px;
    --wx-modal-padding: 16px 20px;
    --wx-modal-gutter: 14px;
    --wx-modal-backdrop: rgba(0, 0, 0, 0.5);
    --wx-modal-header-font-family: var(--wx-font-family);
    --wx-modal-header-font-size: var(--wx-font-size-hd);
    --wx-modal-header-line-height: var(--wx-line-height-hd);
    --wx-modal-header-font-weight: var(--wx-font-weight);
    --wx-modal-header-font-color: #000;
    --wx-notice-z-index: 1010;
    --wx-notice-background: var(--wx-background);
    --wx-notice-shadow: var(--wx-shadow-medium);
    --wx-notice-border: none;
    --wx-notice-border-radius: var(--wx-border-radius);
    --wx-notice-margin: 6px 12px;
    --wx-notice-font-family: var(--wx-font-family);
    --wx-notice-font-size: var(--wx-font-size);
    --wx-notice-line-height: var(--wx-line-height);
    --wx-notice-font-weight: var(--wx-font-weight);
    --wx-notice-font-color: var(--wx-color-font);
    --wx-notice-padding: var(--wx-padding);
    --wx-notice-width: 240px;
    --wx-notice-icon-size: var(--wx-icon-size);
    --wx-notice-icon-color: var(--wx-icon-color);
    --wx-notice-type-border-width: 0px;
    --wx-notice-type-border-color: transparent;
    --wx-notice-type-font-color: #fff;
    --wx-notice-type-icon-color: rgba(255, 255, 255, 0.8);
    --wx-notice-type-background-opacity: 1;
    --wx-notice-type-close-hover-opacity: 0.3;
    --wx-uploader-background: var(--wx-background-alt);
    --wx-calendar-padding: 16px;
    --wx-calendar-cell-size: 32px;
    --wx-calendar-gap: 4px;
    --wx-calendar-line-gap: 0px;
    --wx-calendar-border-radius: var(--wx-calendar-cell-size);
    --wx-calendar-font-family: var(--wx-font-family);
    --wx-calendar-font-size: var(--wx-font-size);
    --wx-calendar-line-height: var(--wx-line-height);
    --wx-calendar-font-weight: var(--wx-font-weight);
    --wx-calendar-font-color: var(--wx-color-font);
    --wx-calendar-icon-color: var(--wx-icon-color);
    --wx-calendar-icon-size: var(--wx-icon-size);
    --wx-calendar-header-font-size: var(--wx-font-size);
    --wx-calendar-header-line-height: var(--wx-line-height);
    --wx-calendar-header-font-weight: var(--wx-font-weight-md);
    --wx-calendar-controls-font-family: var(--wx-button-font-family);
    --wx-calendar-controls-font-size: var(--wx-button-font-size);
    --wx-calendar-controls-line-height: var(--wx-button-line-height);
    --wx-calendar-controls-font-weight: var(--wx-button-font-weigth);
    --wx-calendar-controls-font-color: var(--wx-color-link)
}

.wx-material-theme {
    font-family: var(--wx-font-family);
    font-size: var(--wx-font-size);
    line-height: var(--wx-line-height);
    font-weight: var(--wx-font-weight);
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--wx-color-font);
    background: var(--wx-background);
    height: 90%;
}

.wx-material-theme *,
.wx-material-theme *:before,
.wx-material-theme *:after {
    box-sizing: border-box
}

.label.svelte-1v47mbj {
    display: flex;
    align-items: center
}

.label.active.svelte-1v47mbj {
    background-color: var(--wx-background-alt)
}

input.svelte-1v47mbj {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0
}

.dropzone.svelte-1v47mbj {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--wx-padding);
    border: var(--wx-input-border);
    border-style: dashed;
    border-radius: var(--wx-input-border-radius);
    background: var(--wx-uploader-background)
}

.action.svelte-1v47mbj {
    cursor: pointer;
    color: var(--wx-color-link);
    text-decoration: underline
}

.layout.svelte-kjrvw8.svelte-kjrvw8 {
    display: flex;
    flex-direction: column;
    width: 100%
}

.header.svelte-kjrvw8.svelte-kjrvw8 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--wx-padding);
    border-bottom: var(--wx-border)
}

.list.svelte-kjrvw8.svelte-kjrvw8 {
    overflow: auto
}

.row.svelte-kjrvw8.svelte-kjrvw8 {
    display: flex;
    align-items: center;
    gap: var(--wx-padding);
    padding: var(--wx-padding);
    border-bottom: var(--wx-border)
}

.name.svelte-kjrvw8.svelte-kjrvw8 {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.icon.svelte-kjrvw8.svelte-kjrvw8 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--wx-icon-size);
    height: var(--wx-icon-size);
    font-size: var(--wx-icon-size);
    line-height: 1;
    border-radius: var(--wx-border-radius);
    color: var(--wx-icon-color);
    cursor: pointer
}

.icon.svelte-kjrvw8.svelte-kjrvw8:before {
    display: block
}

.icon.svelte-kjrvw8.svelte-kjrvw8:hover {
    background-color: var(--wx-background-hover)
}

.row.svelte-kjrvw8:hover .wxi-close.svelte-kjrvw8 {
    display: flex
}

.row.svelte-kjrvw8:hover .wxi-check.svelte-kjrvw8, .row.svelte-kjrvw8:hover .wxi-alert.svelte-kjrvw8 {
    display: none
}

.row.svelte-kjrvw8 .wxi-close.svelte-kjrvw8 {
    display: none
}

.wxi-check.svelte-kjrvw8.svelte-kjrvw8, .wxi-alert.svelte-kjrvw8.svelte-kjrvw8 {
    display: flex
}

.wx-event-calendar-uploader.svelte-1qmplch {
    gap: 10px;
    display: flex;
    flex-direction: column
}

.wx-event-calendar-uploader-icon.svelte-ewrdcu {
    color: var(--wx-icon-color);
    display: flex;
    align-items: center
}

.wx-event-calendar-uploader-clickable.svelte-ewrdcu {
    cursor: pointer
}

.wx-event-calendar-uploader-clickable.svelte-ewrdcu:hover::before {
    color: var(--wx-color-primary) !important
}

.wxi-active.svelte-ewrdcu::before {
    color: var(--wx-color-primary)
}

.wx-event-calendar-uploader-label.svelte-1hvwdk5 {
    display: flex;
    align-items: center;
    width: 100%
}

.wx-event-calendar-uploader-label.active.svelte-1hvwdk5 {
    background-color: var(--wx-secondary-color-hover)
}

.wx-event-calendar-uploader-input.svelte-1hvwdk5 {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0
}

.wx-event-calendar-uploader-dropzone.svelte-1hvwdk5 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    border: 1px dashed var(--wx-color-primary);
    padding: 10px
}

.wx-event-calendar-uploader-action.svelte-1hvwdk5 {
    cursor: pointer;
    color: var(--wx-color-primary);
    text-decoration: underline
}

.wx-event-calendar-uploader-layout.svelte-hgd0nw.svelte-hgd0nw {
    display: flex;
    flex-direction: column;
    width: 100%
}

i.svelte-hgd0nw.svelte-hgd0nw {
    cursor: pointer
}

.wx-event-calendar-uploader-header.svelte-hgd0nw.svelte-hgd0nw {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: right
}

.wx-event-calendar-uploader-list.svelte-hgd0nw.svelte-hgd0nw {
    overflow: auto
}

.wx-event-calendar-uploader-row.svelte-hgd0nw.svelte-hgd0nw {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    flex-direction: column
}

.wx-event-calendar-uploader-name.svelte-hgd0nw.svelte-hgd0nw {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis
}

.wx-event-calendar-uploader-controls.svelte-hgd0nw.svelte-hgd0nw {
    display: flex;
    height: 80px;
    width: 100%;
    justify-content: space-around
}

.wx-event-calendar-uploader-hidden.svelte-hgd0nw.svelte-hgd0nw {
    display: none;
    width: 100%;
    justify-content: space-around
}

.wx-event-calendar-uploader-row.svelte-hgd0nw:hover .wx-event-calendar-uploader-hidden.svelte-hgd0nw {
    display: flex
}

.wx-event-calendar-uploader-file-icon.svelte-hgd0nw.svelte-hgd0nw {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.wx-event-calendar-uploader-thumb.svelte-hgd0nw.svelte-hgd0nw {
    width: 100%;
    max-width: 100%;
    max-height: 100%
}

.wx-event-calendar-uploader-upload-link.svelte-hgd0nw.svelte-hgd0nw {
    text-decoration: none;
    display: flex
}

.wx-event-calendar-agenda-wrapper.svelte-1lkd52o {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto
}

.wx-event-calendar-agenda-no-events.svelte-1lkd52o {
    align-self: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center
}

.wx-event-calendar-agenda-day-wrapper.svelte-xcdk9b {
    display: flex;
    flex-shrink: 0;
    overflow-x: clip
}

.wx-event-calendar-agenda-date.svelte-xcdk9b {
    cursor: pointer;
    width: var(--wx-event-calendar_agenda-scale-width);
    padding: 6px 12px;
    border-bottom: var(--wx-border);
    flex-shrink: 0
}

.wx-event-calendar-agenda-date.svelte-xcdk9b:hover {
    background: var(--wx-background-hover)
}

.wx-event-calendar-agenda-events-wrapper.svelte-xcdk9b {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 2px 0px 14px 0px;
    border: var(--wx-border);
    border-top: none;
    position: relative
}

.wx-event-calendar-agenda-event-wrapper.svelte-18hj0s5 {
    padding: 0px 12px;
    margin-top: 4px
}

.wx-event-calendar-agenda-event-wrapper.svelte-18hj0s5:hover {
    background: var(--wx-color-disabled)
}

.wx-event-calendar-agenda-event-selected.svelte-18hj0s5 {
    background: var(--wx-background-hover)
}

.wx-event-calendar-wrapper.svelte-gq221o {
    flex: 1;
    display: flex;
    flex-direction: column
}

.wx-event-calendar-grid.svelte-gq221o {
    display: flex;
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    position: relative
}

.wx-event-calendar-header.svelte-gq221o {
    display: flex;
    margin-left: -1px;
    border-bottom: var(--wx-border)
}

.wx-event-calendar-header-date.svelte-gq221o {
    flex: 1 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer
}

.wx-event-calendar-header-data.svelte-gq221o {
    display: flex;
    flex-grow: 1
}

.wx-event-calendar-header-date.svelte-gq221o:hover {
    background: var(--wx-background-hover)
}

.wx-event-calendar-placeholder.svelte-gq221o, .wx-event-calendar-placeholder-multiday.svelte-gq221o {
    height: 100%;
    flex-shrink: 0;
    width: var(--wx-event-calendar_hour-scale-width)
}

.wx-event-calendar-placeholder-multiday.svelte-gq221o {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: var(--wx-border)
}

.wx-event-calendar-multievents-wrapper.svelte-gq221o {
    border-bottom: var(--wx-border);
    display: flex;
    box-sizing: content-box
}

.wx-event-calendar-multievents-grid.svelte-gq221o {
    position: relative;
    flex-grow: 1;
    display: flex
}

.wx-event-calendar-data.svelte-gq221o {
    width: 100%;
    display: flex;
    height: -moz-fit-content;
    height: fit-content
}

.wx-event-calendar-multiday.svelte-gq221o {
    display: flex;
    flex-grow: 1;
    height: 100%
}

.wx-event-calendar-wrapprer.svelte-oh6b0f {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    overflow: visible
}

.wx-event-calendar-time.svelte-oh6b0f {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: var(--wx-border);
    border-bottom: var(--wx-border);
    flex-grow: 0;
    flex-shrink: 0;
    height: var(--wx-event-calendar_hour-cell-height)
}

.wx-event-calendar-today.svelte-oh6b0f {
    background-color: var(--wx-background-alt)
}

.wx-event-calendar-event-box.svelte-1rqce43.svelte-1rqce43 {
    flex-direction: column;
    position: absolute;
    border: 1px solid var(--wx-border-color);
    border-left: 5px solid var(--wx-border-color);
    background-color: var(--wx-background);
    cursor: default;
    border-radius: 3px;
    color: var(--wx-color-primary-font);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    display: flex;
    align-items: center;
    align-items: flex-start;
    padding: 6px;
    min-height: var(--wx-line-height-sm);
    transition: left 0.2s, width 0.2s
}

.wx-event-calendar-readonly.svelte-1rqce43.svelte-1rqce43 {
    cursor: default
}

.wx-event-calendar-resizer.svelte-1rqce43.svelte-1rqce43 {
    display: flex;
    justify-content: center;
    cursor: n-resize;
    width: 100%;
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    line-height: 10px;
    visibility: hidden;
    color: var(--wx-color-primary-font);
    background-color: var(--wx-background)
}

.wx-event-calendar-event-box--small {
    justify-content: space-between;
    gap: 2px;
    flex-direction: row !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.wx-event-calendar-event-box--small .wx-event-calendar-resizer.svelte-1rqce43.svelte-1rqce43 {
    background-color: unset !important
}

.wx-event-calendar-event-box.svelte-1rqce43:hover .wx-event-calendar-resizer.svelte-1rqce43 {
    visibility: visible !important
}

.wx-event-calendar-selected.svelte-1rqce43.svelte-1rqce43 {
    box-shadow: var(--wx-popup-shadow)
}

.wx-event-calendar-time-column-wrapper.svelte-z8n3w6 {
    display: flex;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
    width: var(--wx-event-calendar_hour-scale-width)
}

.wx-event-calendar-time-column-time.svelte-z8n3w6 {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: var(--wx-border);
    border-bottom: var(--wx-border);
    flex-grow: 0;
    flex-shrink: 0;
    width: var(--wx-event-calendar_hour-scale-width);
    height: var(--wx-event-calendar_hour-cell-height)
}

.wx-event-calendar-wrapper.svelte-83l7pw {
    width: 244px;
    margin-top: 25px
}

.wx-event-calendar-wrapper.svelte-83l7pw label {
    display: flex;
    align-items: start
}

.wx-event-calendar-name.svelte-83l7pw {
    display: flex;
    gap: 10px
}

.wx-event-calendar-colorpicker.svelte-83l7pw {
    width: 70px
}

.wx-event-calendar-title.svelte-83l7pw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--wx-font-family);
    font-size: var(--wx-font-size-md);
    font-weight: var(--wx-font-weight-b);
    color: var(--wx-color-font)
}

.wxi-close.svelte-83l7pw {
    font-size: var(--wx-line-height);
    color: var(--wx-icon-color);
    cursor: pointer
}

.wx-event-calendar-button.svelte-83l7pw {
    display: flex;
    justify-content: end
}

.popup {
    overflow: visible !important
}

.wx-event-calendar-wrapper.svelte-4uhmxq {
    width: 244px
}

.wx-event-calendar-item.svelte-4uhmxq {
    padding: 6px 11px;
    cursor: pointer
}

.wx-event-calendar-colorpicker.svelte-4uhmxq {
    padding-bottom: 0
}

.wx-event-calendar-delete.svelte-4uhmxq {
    color: var(--wx-color-danger);
    border-top: var(--wx-border)
}

.wx-event-calendar-wrapper.svelte-12sleti {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    font-size: var(--wx-font-size);
    position: relative
}

.wx-event-calendar-calendar_item.svelte-12sleti, .wx-event-calendar-title.svelte-12sleti {
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border-top: var(--wx-border);
    width: 100%;
    height: 36px;
    flex-shrink: 0
}

.wx-event-calendar-calendar_item.svelte-12sleti:first-of-type {
    border-top: none
}

.wx-event-calendar-calendars-list.svelte-12sleti {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    margin-top: 36px
}

.wx-event-calendar-calendar_item.svelte-12sleti:hover {
    background-color: var(--wx-background-alt)
}

.wx-event-calendar-title.svelte-12sleti {
    justify-content: space-between;
    cursor: pointer;
    padding: 6px 12px;
    position: absolute;
    border-bottom: var(--wx-border)
}

.wx-event-calendar-name.svelte-12sleti {
    font-weight: 600
}

.wxi.svelte-12sleti {
    color: var(--wx-color-font-disabled);
    font-size: var(--wx-line-height)
}

.wx-event-calendar-buttons.svelte-12sleti {
    display: flex;
    align-items: center;
    gap: 8px
}

.wx-event-calendar-wrapper.svelte-961af7 {
    width: 0;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    background-color: var(--wx-background);
    color: var(--wx-color-font);
    display: flex;
    gap: 10px;
    overflow-x: hidden;
    transition: width 0.5s ease-in-out
}

.wx-event-calendar-calendars_wrapper.svelte-961af7 {
    flex-grow: 1;
    overflow: hidden
}

.wx-event-calendar-show.svelte-961af7 {
    border-right: var(--wx-border);
    width: 249px
}

.wrapper {
    --wx-calendar-padding: 20px 10px !important;
    --wx-calendar-cell-gap: 5px !important
}

.wx-event-calendar-wrapper.svelte-1n3q3yp.svelte-1n3q3yp {
    display: flex;
    font-size: var(--wx-font-size);
    align-items: center;
    width: 100%;
    padding: 6px 12px
}

.wx-event-calendar-wrapper.svelte-1n3q3yp.svelte-1n3q3yp:hover {
    background-color: var(--wx-background-hover)
}

.wx-event-calendar-wrapper.svelte-1n3q3yp:hover > .wxi-dots-v.svelte-1n3q3yp {
    display: flex
}

.wx-event-calendar-checkbox.svelte-1n3q3yp.svelte-1n3q3yp {
    width: 18px;
    height: 18px;
    border: 1px solid var(--wx-border);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 2px
}

.wx-event-calendar-active.svelte-1n3q3yp.svelte-1n3q3yp {
    background-color: var(--wx-border)
}

.wx-event-calendar-label.svelte-1n3q3yp.svelte-1n3q3yp {
    flex-grow: 1
}

.wxi-dots-v.svelte-1n3q3yp.svelte-1n3q3yp {
    color: var(--wx-color-font-disabled);
    font-size: var(--wx-line-height);
    margin-top: 2px;
    display: none
}

.wxi-check.svelte-1n3q3yp.svelte-1n3q3yp {
    color: #ffff;
    font-size: 14px;
    margin-top: 2px
}

.wx-event-calendar-flex-col.svelte-1may3oa {
    display: flex
}

.wx-event-calendar-text-wrapper.svelte-1may3oa {
    width: 65px;
    flex-shrink: 0;
    margin-right: 10px
}

.wx-event-calendar-text-wrapper input {
    text-align: center;
    -moz-appearance: textfield
}

.wx-event-calendar-text-wrapper
input::-webkit-outer-spin-button, .wx-event-calendar-text-wrapper
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.wx-event-calendar-ends-a-wrapper.svelte-1may3oa {
    display: flex;
    margin-left: 10px;
    align-items: center
}

.wx-event-calendar-week-control-wrapper.svelte-12gyefb {
    display: inline-flex;
    flex-wrap: nowrap;
    background: var(--wx-segmented-background);
    border: var(--wx-segmented-border);
    border-radius: var(--wx-segmented-border-radius);
    max-width: 100%
}

.wx-event-calendar-week-button.svelte-12gyefb {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    letter-spacing: normal;
    font-family: var(--wx-button-font-family);
    font-size: var(--wx-button-font-size);
    line-height: var(--wx-button-line-height);
    font-weight: var(--wx-button-font-weigth);
    background: transparent;
    color: var(--wx-button-font-color);
    cursor: pointer;
    box-shadow: none;
    transition: none;
    max-width: 100%;
    width: 53px;
    height: 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.wx-event-calendar-week-button.svelte-12gyefb, .wx-event-calendar-week-button.svelte-12gyefb:focus, .wx-event-calendar-week-button.svelte-12gyefb:active {
    outline: none
}

.wx-event-calendar-week-button.svelte-12gyefb:hover {
    background: var(--wx-segmented-background-hover)
}

.wx-event-calendar-week-button.selected.svelte-12gyefb, .wx-event-calendar-week-button.selected.svelte-12gyefb:hover, .wx-event-calendar-week-button.selected.svelte-12gyefb:focus {
    background: var(--wx-color-primary);
    color: var(--wx-color-primary-font);
    cursor: default
}

.wx-event-calendar * {
    transition: all 0.2s
}

.wx-event-calendar.svelte-1hdh83m {
    --wx-event-calendar_hour-scale-width: 70px;
    --wx-event-calendar_hour-cell-height: 42px;
    --wx-event-calendar_month-cell-min-height: 200px;
    --wx-event-calendar_agenda-scale-width: 110px
}

.wx-event-calendar-layout.svelte-1hdh83m {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    font-family: var(--wx-font-family);
    font-size: var(--wx-font-size);
    background-color: var(--wx-background);
    color: var(--wx-color-font)
}

.wx-event-calendar-content.svelte-1hdh83m, .wx-event-calendar-grid.svelte-1hdh83m {
    flex: 1;
    display: flex;
    overflow: hidden;
    position: relative
}

.wx-event-calendar_mark.svelte-1hdh83m {
    position: absolute;
    right: 34px;
    top: 12px;
    transform: rotate(30deg);
    color: #ccc;
    font-weight: 500;
    text-transform: uppercase;
    background: inherit;
    z-index: 10
}

.wx-event-calendar_mark.wx-event-calendar_mark--error.svelte-1hdh83m {
    color: red
}

.wx-event-calendar-right.svelte-1hdh83m {
    max-width: 0;
    width: 0
}

.wx-event-calendar-right-show.svelte-1hdh83m {
    max-width: 400px;
    width: 400px
}

.wx-event-calendar-time-line-wrapper-horizontal.svelte-7jfwl9 {
    position: absolute;
    display: flex;
    align-items: center;
    left: -2px;
    width: 100%;
    pointer-events: none
}

.wx-event-calendar-time-line-wrapper-vertical.svelte-7jfwl9 {
    position: absolute;
    display: flex;
    justify-content: center;
    top: -2px;
    height: 100%;
    pointer-events: none
}

.wx-event-calendar-time-circle-horizontal.svelte-7jfwl9 {
    background: var(--wx-color-danger);
    border-radius: 50%;
    content: "";
    position: absolute;
    height: 12px;
    margin-left: -6.5px;
    width: 12px;
    z-index: calc(var(--wx-popup-z-index) - 1)
}

.wx-event-calendar-time-circle-vertical.svelte-7jfwl9 {
    background: var(--wx-color-danger);
    border-radius: 50%;
    content: "";
    position: absolute;
    height: 12px;
    margin-top: 2px;
    width: 12px
}

.wx-event-calendar-time-line-horizontal.svelte-7jfwl9 {
    max-width: 100%;
    flex-grow: 1;
    z-index: calc(var(--wx-popup-z-index) - 1);
    border-top: var(--wx-color-danger) solid 2px
}

.wx-event-calendar-time-line-vertical.svelte-7jfwl9 {
    max-height: 100%;
    flex-grow: 1;
    border-left: var(--wx-color-danger) solid 2px
}

.wx-event-calendar-event.svelte-1ak1z09 {
    position: absolute;
    gap: 10px;
    padding: 0 6px;
    color: var(--wx-color-font);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    transition: top 0.2s, left 0.2s, width 0.2s
}

.wx-event-calendar-readonly.svelte-1ak1z09 {
    cursor: default
}

.wx-event-calendar-layout.svelte-ywzoz9 {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--wx-color-font)
}

.wx-event-calendar-weekdays.svelte-ywzoz9 {
    display: flex;
    border-bottom: var(--wx-border);
    margin-left: -1px;
    color: var(--wx-color-font-alt)
}

.wx-event-calendar-weekday.svelte-ywzoz9 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-shrink: 0;
    text-transform: capitalize
}

.wx-event-calendar-table.svelte-ywzoz9 {
    flex: 1;
    overflow: auto
}

.wx-event-calendar-event.svelte-evfijt.svelte-evfijt {
    position: absolute;
    padding: 0 6px;
    border: 1px solid var(--wx-border-color);
    border-radius: 3px;
    color: var(--wx-color-primary-font);
    background-color: var(--wx-background);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: top 0.2s, left 0.2s, width 0.2s
}

.wx-event-calendar-readonly.svelte-evfijt.svelte-evfijt {
    cursor: default
}

.wx-event-calendar-resizer.svelte-evfijt.svelte-evfijt {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
    visibility: hidden;
    height: 100%;
    right: 0;
    cursor: w-resize;
    align-items: center
}

.wx-event-calendar-event.svelte-evfijt:hover .wx-event-calendar-resizer.svelte-evfijt {
    visibility: visible
}

.wx-event-calendar-selected.svelte-evfijt.svelte-evfijt {
    box-shadow: var(--wx-popup-shadow)
}

.wx-event-calendar-today.svelte-1yhoro0 {
    position: absolute;
    width: 100%;
    padding: 0 6px;
    color: var(--wx-show-all-color);
    font-weight: var(--wx-font-weight-md);
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer
}

.wx-event-calendar-today.svelte-1yhoro0:hover {
    color: var(--wx-show-all-color-hover)
}

.wx-event-calendar-calendar-week.svelte-1s1ahhr.svelte-1s1ahhr {
    position: relative;
    display: grid;
    grid-template-columns:repeat(7, 1fr);
    border-bottom: var(--wx-border);
    min-height: var(--wx-event-calendar_month-cell-min-height)
}

.wx-event-calendar-day.svelte-1s1ahhr.svelte-1s1ahhr {
    position: relative;
    border-right: var(--wx-border)
}

.wx-event-calendar-day.svelte-1s1ahhr.svelte-1s1ahhr:last-child {
    border-right: none
}

.wx-event-calendar-date.svelte-1s1ahhr.svelte-1s1ahhr {
    height: 30px;
    text-align: right;
    margin: 0 10px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: end;
    flex-shrink: 0
}

.wx-event-calendar-date-label.svelte-1s1ahhr.svelte-1s1ahhr {
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%
}

.wx-event-calendar-date-label.svelte-1s1ahhr.svelte-1s1ahhr:hover {
    background: var(--wx-background-hover)
}

.wx-event-calendar-inactive.svelte-1s1ahhr .wx-event-calendar-date.svelte-1s1ahhr {
    color: var(--wx-color-font-disabled)
}

.wx-event-calendar-today.svelte-1s1ahhr.svelte-1s1ahhr {
    background-color: var(--wx-background-alt)
}

.wx-event-calendar-toolbar_wrapper.svelte-1rx91km {
    display: flex;
    flex-direction: column;
    align-items: center
}

.wx-event-calendar-toolbar.svelte-1rx91km {
    display: flex;
    flex-basis: 56px;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    border-bottom: var(--wx-border);
    width: 100%;
}

.wx-event-calendar-left.svelte-1rx91km {
    display: flex;
    align-items: center;
    gap: 12px
}

.wx-event-calendar-right.svelte-1rx91km {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end
}

.wx-event-calendar-btn-add.svelte-1rx91km {
    display: flex;
    align-items: center;
    gap: 10px
}

.wx-event-calendar-icon.svelte-1rx91km {
    width: 28px;
    height: 28px;
    cursor: pointer
}

.wx-event-calendar-icon.svelte-1rx91km:hover {
    opacity: 0.7
}

.wx-event-calendar-menu.svelte-1rx91km {
    background: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H18V2H0V0ZM0 5H18V7H0V5ZM0 10H18V12H0V10Z' fill='%239FA1AE'/%3E%3C/svg%3E%0A") no-repeat center
}

.wx-event-calendar-plus.svelte-1rx91km {
    display: flex;
    align-items: center
}

.wx-event-calendar-plus.svelte-1rx91km::before {
    font-weight: var(--wx-font-weight-b) !important
}

.wx-event-calendar-select-wrapper.svelte-1mm68v1 {
    width: -moz-max-content;
    width: max-content
}

.wx-event-calendar-wrapper.svelte-9757ut {
    position: relative;
    display: flex;
    align-items: center;
    font-size: var(--wx-font-size-md);
    font-weight: var(--wx-font-weight-md);
    flex-grow: 30;
    justify-content: center;
    padding: 10px 0
}

.wx-event-calendar-title.svelte-9757ut {
    text-transform: capitalize;
    cursor: pointer;
    min-width: 70px;
    text-align: center;
    font-size: 12px;
}

.wx-event-calendar-icon.svelte-9757ut {
    cursor: pointer;
    color: var(--wx-icon-color);
    height: var(--wx-icon-size);
    width: var(--wx-icon-size);
    font-size: var(--wx-line-height-md)
}

.wx-event-calendar-icon.svelte-9757ut::before {
    font-weight: var(--wx-font-weight-md) !important
}

.wx-event-calendar-title.svelte-9757ut:hover, .wx-event-calendar-icon.svelte-9757ut:hover {
    opacity: 0.7
}

.wx-event-calendar-calendar-wrapper.svelte-9757ut {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 185px;
    transform: translateX(-50%);
    z-index: 10
}

.wx-event-calendar-editor-wrapper.svelte-1tzsplj.svelte-1tzsplj {
    padding: 0px 12px;
    height: 100%;
    padding-top: 0px
}

.wx-event-calendar-close.svelte-1tzsplj.svelte-1tzsplj, .wx-event-calendar-loading-wrapper.svelte-1tzsplj.svelte-1tzsplj {
    cursor: pointer;
    font-size: var(--wx-font-size-md);
    flex-grow: 1;
    display: flex;
    gap: 25px
}

.wx-event-calendar-loading.svelte-1tzsplj.svelte-1tzsplj {
    cursor: default;
    transform: rotate(0);
    animation: svelte-1tzsplj-spin 1s linear infinite
}

@keyframes svelte-1tzsplj-spin {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

.wx-event-calendar-editor-controls.svelte-1tzsplj.svelte-1tzsplj {
    display: flex;
    width: calc(100% + 24px);
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    position: sticky;
    top: 0;
    left: 0;
    padding: 12px;
    margin-left: -12px;
    z-index: 5;
    background: var(--wx-background);
    border-bottom: var(--wx-border)
}

.wx-event-calendar-date_field.svelte-1tzsplj.svelte-1tzsplj {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.wx-event-calendar-date_field.svelte-1tzsplj .wx-event-calendar-input_wrapper.svelte-1tzsplj {
    flex-grow: 2
}

.wx-event-calendar-date_field.svelte-1tzsplj .timepicker {
    width: unset !important
}

.wx-event-calendar-input_wrapper.svelte-1tzsplj.svelte-1tzsplj {
    margin-right: 10px
}

.wx-event-calendar-editor-controls > .btn:not(:last-child) {
    margin-right: 5px
}

.wx-event-calendar-combo-option-wrapper.svelte-1tzsplj.svelte-1tzsplj {
    width: 100%;
    height: 100%
}

.wx-event-calendar-editor-form.svelte-1tzsplj.svelte-1tzsplj {
    padding-bottom: 12px
}

.wx-event-calendar-collapsed-wrapper.svelte-1k546m8 {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 100%
}

.wx-event-calendar-collapsed-child-wrapper.svelte-1k546m8 {
    flex-grow: 1;
    max-height: calc(100% - 35px);
    overflow-y: auto
}

.wx-event-calendar-collapsed-parent-wrapper.svelte-1k546m8 {
    display: flex;
    align-items: center;
    font-weight: var(--wx-font-weight-md)
}

.wx-event-calendar-collapsed-parent.svelte-1k546m8 {
    flex-grow: 1
}

.wxi.svelte-1k546m8 {
    cursor: pointer;
    font-size: var(--wx-icon-size);
    color: var(--wx-icon-color)
}

.wx-unassigned-event.svelte-1k546m8 {
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    cursor: pointer;
    text-overflow: ellipsis
}

.wx-event-calendar-editor.svelte-1qxgf0v {
    flex-basis: 0px;
    z-index: 3;
    position: relative;
    box-shadow: var(--wx-box-shadow);
    background: var(--wx-background);
    overflow-y: auto;
    overflow-x: hidden;
    transition: flex-basis 0.2s;
    height: 100%
}

.wx-event-calendar-editor.show.svelte-1qxgf0v {
    flex-basis: 400px;
    width: 400px;
    max-width: 400px
}

.wx-event-calendar-tabbar.svelte-v7zgto {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    height: 33px
}

.wx-event-calendar-tab.svelte-v7zgto {
    padding: 3px 30px
}

.wx-active-tab.svelte-v7zgto {
    border-bottom: 2px solid var(--wx-color-primary)
}

.wx-event-calendar-unassign-wrapper.svelte-i7p0me {
    padding: 12px;
    height: 100%;
    max-height: 100%;
    overflow-x: hidden;
    max-width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: column;
    padding: 12px
}

.wx-event-calendar-unassigned-icons.svelte-i7p0me {
    cursor: pointer;
    color: var(--wx-icon-color);
    font-size: var(--wx-icon-size)
}

.wx-event-calendar-editor-controls.svelte-i7p0me {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    max-width: 100%;
    min-width: 0
}

.wx-unassigned-text.svelte-i7p0me {
    font-weight: var(--wx-font-weight-md);
    flex-grow: 1;
    line-height: 0;
    margin-top: -1px;
    min-width: 0
}

.wx-event-calendar-unassigned-search.svelte-i7p0me {
    position: relative;
    margin-bottom: 12px
}

.wx-search-icon.svelte-i7p0me {
    position: absolute;
    left: var(--wx-input-icon-indent);
    top: 50%;
    transform: translateY(-50%);
    font-size: var(--wx-input-icon-size);
    line-height: 1;
    width: var(--wx-input-icon-size);
    height: var(--wx-input-icon-size);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: var(--wx-input-icon-color)
}

.wx-unassigned-events-header.svelte-i7p0me {
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    background-color: var(--wx-background-alt);
    font-size: var(--wx-font-size-sm)
}

.wx-tabbar-wrapper.svelte-i7p0me {
    margin-bottom: 20px
}

.wx-unassigned-events-wrapper.svelte-1imx23f {
    flex-grow: 1;
    overflow-y: auto
}

.wx-unassigned-events-wrapper.svelte-1imx23f {
    flex-grow: 1;
    overflow-y: auto
}

.wx-event-calendar-timeline-data.svelte-q8o9j3 {
    background-color: var(--wx-background);
    color: var(--wx-color-font);
    text-align: center;
    flex-shrink: 0;
    border-bottom: var(--wx-border);
    box-sizing: border-box;
    display: table-cell
}

.wx-event-calendar-timeline-section.svelte-q8o9j3 {
    position: sticky;
    left: 0;
    background-color: var(--wx-background);
    flex-shrink: 0;
    z-index: 1;
    width: 100%;
    border-right: var(--wx-border)
}

.wx-event-calendar-timeline-date.svelte-q8o9j3 {
    border-right: var(--wx-border)
}

.wx-unassigned-events.svelte-1f7qacv {
    width: 50px;
    height: 100%;
    overflow: hidden;
    box-shadow: var(--wx-box-shadow);
    background-color: var(--wx-background-alt);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    padding: 16px;
    cursor: pointer
}

.wx-unassigned-collapse-icon.svelte-1f7qacv {
    font-size: var(--wx-icon-size);
    color: var(--wx-icon-color)
}

.wx-unassigned-text.svelte-1f7qacv {
    margin-top: 14px;
    position: absolute;
    font-weight: var(--wx-font-weight-md);
    writing-mode: vertical-rl
}

.table.svelte-1f7qacv {
    font-family: "Fraunces", serif;
    font-size: 125%;
    white-space: nowrap;
    margin: 0;
    border: none;
    border-spacing: 0;
    table-layout: fixed;
    font-family: var(--wx-font-family);
    font-size: var(--wx-font-size)
}

.wx-event-calendar-wrapper.svelte-1f7qacv {
    width: 100%;
    overflow: auto;
    position: relative
}

.wx-event-calendar-timeline-row.svelte-1f7qacv {
    border-bottom: var(--wx-border);
    display: table-row;
    flex-shrink: 0;
    width: -moz-fit-content;
    width: fit-content;
    transition: 0.3s
}

.wx-event-calendar-timeline-body.svelte-1f7qacv {
    position: relative
}

.wx-timeline-dragged.svelte-k8mkhp.svelte-k8mkhp {
    z-index: 4 !important
}

.wx-event-calendar-event.svelte-k8mkhp.svelte-k8mkhp {
    position: absolute;
    padding: 0 6px;
    border: 1px solid var(--wx-border-color);
    border-radius: 3px;
    color: var(--wx-color-primary-font);
    background-color: var(--wx-background);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    cursor: pointer
}

.wx-event-calendar-readonly.svelte-k8mkhp.svelte-k8mkhp {
    cursor: default
}

.wx-event-calendar-resizer.svelte-k8mkhp.svelte-k8mkhp {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
    visibility: hidden;
    height: 100%;
    right: 0;
    cursor: w-resize;
    align-items: center
}

.wx-event-calendar-event.svelte-k8mkhp:hover .wx-event-calendar-resizer.svelte-k8mkhp {
    visibility: visible
}

.wx-event-calendar-selected.svelte-k8mkhp.svelte-k8mkhp {
    box-shadow: var(--wx-popup-shadow)
}

.wx-event-calendar-timeline-row.svelte-u67q0x {
    border-bottom: var(--wx-border);
    display: table-row;
    flex-shrink: 0;
    width: -moz-fit-content;
    width: fit-content;
    transition: 0.3s
}

.wx-event-calendar-timeline-header-cell.svelte-u67q0x {
    padding: 3px;
    position: sticky;
    height: 32px;
    z-index: 1;
    border-right: var(--wx-border);
    border-bottom: var(--wx-border);
    background-color: var(--wx-background);
    box-sizing: border-box;
    overflow: hidden;
    display: table-cell;
    color: var(--wx-color-font-alt)
}

.wx-event-calendar-timeline-header-placeholder.svelte-u67q0x {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 2;
    flex-shrink: 0;
    padding: 4px 7px;
    border-right: var(--wx-border);
    background-color: var(--wx-background);
    vertical-align: middle;
    font-weight: var(--wx-font-weight)
}

.wx-event-calendar-timeline-row.svelte-u67q0x {
    border-bottom: var(--wx-border);
    display: table-row;
    flex-shrink: 0;
    width: -moz-fit-content;
    width: fit-content;
    transition: 0.3s
}

.wx-event-calendar-timeline-header-cell.svelte-12eazk3 {
    padding: 3px;
    position: sticky;
    z-index: 1;
    height: 32px;
    border-right: var(--wx-border);
    border-bottom: var(--wx-border);
    background-color: var(--wx-background);
    box-sizing: border-box;
    overflow: hidden;
    display: table-cell;
    color: var(--wx-color-font-alt);
    font-weight: var(--wx-font-weight)
}

.wx-event-calendar-timeline-header-date.svelte-12eazk3 {
    border-right: var(--wx-border);
    flex-shrink: 0;
    padding: 0;
    z-index: 1;
    position: sticky
}

.wx-event-calendar-month-wrapper.svelte-agn56g {
    width: 260px;
    --wx-calendar-padding: 20px 10px !important;
    --wx-calendar-cell-gap: 8px !important
}

.wx-event-calendar-month-title.svelte-agn56g {
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
    color: var(--wx-color-font);
    font-weight: var(--wx-font-weight-md)
}

.wx-event-calendar-month-wrapper .day {
    position: relative
}

.wx-event-calendar-year-popup-wrapper.svelte-o9bmpl {
    padding: 18px 24px
}

.wx-event-calendar-year-popup-title.svelte-o9bmpl {
    font-size: var(--wx-font-size-md);
    font-weight: 700;
    margin-bottom: 17px
}

.wx-event-calendar-event.svelte-o9bmpl {
    gap: 10px;
    color: var(--wx-color-font);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer
}

.wx-event-calendar-multievent.svelte-o9bmpl {
    padding: 0 6px;
    margin-bottom: 4px;
    border: 1px solid var(--wx-border-color);
    border-radius: 3px;
    color: var(--wx-color-primary-font);
    background-color: var(--wx-background);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    cursor: pointer
}

.wx-event-calendar-readonly.svelte-o9bmpl {
    cursor: default
}

.wx-event-calendar-year-view-wrapper.svelte-6lbanu {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
    padding: 60px 0
}

.wx-event-calendar-year-view-wrapper
.wx-event-calendar-month-marker::after {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    bottom: 2px;
    left: 13px;
    background-color: var(--wx-color-primary)
}

.wx-event-calendar-year-view-wrapper
.wx-event-calendar-month-marker {
    font-weight: var(--wx-font-weight-md)
}

.wx-event-calendar-date.svelte-fh65s8 {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    justify-content: center;
    width: 100%
}

.wx-event-calendar-agenda-day.svelte-fh65s8 {
    font-weight: var(--wx-font-weight-md)
}

.wx-event-calendar-agenda-event-marker.svelte-92bs52 {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: var(--wx-background)
}

.wx-event-calendar-agenda-event-time.svelte-92bs52 {
    font-weight: var(--wx-font-weight-md);
    width: 120px;
    flex-shrink: 0;
    text-align: center
}

.wx-event-calendar-label.svelte-92bs52 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    height: 100%
}

.wx-event-calendar-agenda-event-text.svelte-92bs52 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.wx-event-calendar-combo-option.svelte-1lgeqpd {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.wx-event-calendar-color.svelte-1lgeqpd {
    width: 10px;
    height: 10px
}

.wx-event-calendar-date.svelte-1nmcb21 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: var(--wx-color-font-alt)
}

.wx-event-calendar-marker.svelte-1lf4wdf {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: var(--wx-background)
}

.wx-event-calendar-event-time.svelte-1lf4wdf {
    font-weight: var(--wx-font-weight-md)
}

.wx-event-calendar-label.svelte-1lf4wdf {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis
}

.wx-event-calendar-label.svelte-1kn9w2c {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%
}

.wx-event-calendar-info.svelte-tyjvm3 {
    width: 300px;
    display: flex;
    flex-direction: column
}

.wx-event-calendar-checkbox.svelte-tyjvm3 {
    display: inline-block;
    width: 9px;
    height: 9px;
    border-radius: 2px;
    margin-right: 4px
}

.wx-event-calendar-name.svelte-tyjvm3 {
    font-size: var(--wx-font-size-md);
    font-weight: var(--wx-font-weight-b);
    margin: 5px 0
}

.wx-event-calendar-date.svelte-tyjvm3 {
    text-transform: capitalize
}

.wx-event-calendar-controls.svelte-tyjvm3 {
    margin-top: 7px;
    display: flex;
    gap: 8px
}

.wx-event-calendar-control.svelte-tyjvm3 {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    font-style: normal;
    color: var(--wx-color-primary)
}

.wx-event-calendar-label.svelte-tyjvm3 {
    color: var(--wx-color-font)
}

.wx-event-calendar-popup_wrapper.svelte-tyjvm3 {
    padding: 10px 20px 15px 20px;
    width: 400px;
    max-width: 700px;
    font-family: var(--wx-font-family);
    line-height: var(--wx-line-height);
    line-height: var(--wx-line-height-md)
}

.wx-event-calendar-header.svelte-tyjvm3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default
}

.wx-event-calendar-close.svelte-tyjvm3 {
    cursor: pointer;
    color: var(--wx-icon-color);
    font-size: var(--wx-font-size-md)
}

.wx-event-calendar-title.svelte-tyjvm3 {
    font-size: var(--wx-font-size-sm)
}

.wx-section-img.svelte-12im8yh {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 8px
}

.wx-section-template-wrapper.svelte-12im8yh {
    display: flex;
    align-items: center;
    padding: 9px 7px
}

.wx-event-section-label.svelte-12im8yh {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    justify-content: start;
    text-overflow: ellipsis
}

.wx-unassigned-event.svelte-59z0hp {
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    cursor: pointer;
    text-overflow: ellipsis
}

.wx-event-calendar-unassign-date.svelte-59z0hp {
    white-space: nowrap
}

.wx-event-calendar-text.svelte-2p2e9z {
    font-weight: var(--wx-font-weight-md)
}

.wx-event-calendar-time.svelte-2p2e9z {
    flex-shrink: 0;
    font-weight: var(--wx-font-weight)
}




.wx-event-calendar_mark {
    display: none;
}

/*to unvisible built-in edit drawer*/
.wx-event-calendar-right {
    display: none;
}

.svelte-tksh4t {
    background-color: #FF5555;
}

.wx-event-calendar-left div:has(.wx-event-calendar-btn-add){
    background-color: aquamarine!important;
    display: none!important;
}

div[data-id="today"] .svelte-tksh4t {
    background-color: white!important;
    color: #FF5555!important;
}

div[data-id="today"] .svelte-tksh4t:hover {
    background-color: #FFD9D9!important;
    color: #FF5555!important;
}

#content-calendar-root {
    height: 100%;
}

.wx-event-calendar-layout.svelte-1hdh83m {
    border: 1px solid #ccc;
    border-radius: 6px;
}

.wx-event-calendar-name {
    font-weight: bolder!important;
}

.wx-event-calendar-multievents-wrapper.svelte-gq221o {
    overflow-y: auto;
}

.wx-material-theme {
    height: 100%!important;
}
